import PropTypes from "prop-types";

const baseButtonStyles =
  "inline-flex items-center justify-center font-semibold transition ease-in-out duration-200 rounded focus:outline-none focus:ring-4";

const variantStyles = {
  primary:
    "text-white bg-gradient-to-r from-indigo-500 via-blue-500 to-indigo-500 hover:from-indigo-500 hover:via-purple-500 hover:to-indigo-500 shadow-lg shadow-indigo-500/50 focus:ring-indigo-400",
  secondary:
    "text-white bg-green-500 hover:bg-green-700 shadow-md shadow-blue-500/50 focus:ring-blue-400",
  danger:
    "text-white bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-500 shadow-lg shadow-red-500/50 focus:ring-red-400",
};
const sizeStyles = {
  small: "h-8 w-24 px-3 py-1.5 text-sm",
  medium: "px-4 py-2 text-md",
  large: "h-12 w-40 px-5 py-3 text-lg",
};

export const ButtonTW = ({
  type = "button",
  label,
  onClick,
  variant = "primary",
  size = "medium",
  disabled = false,
  className = "",
}) => {
  const buttonClassNames = `${baseButtonStyles} ${variantStyles[variant]} ${
    sizeStyles[size]
  } ${
    disabled ? "opacity-50 cursor-not-allowed" : ""
  } transform hover:scale-105 hover:shadow-2xl ${className}`;

  return (
    <button
      type={type}
      onClick={onClick}
      className={buttonClassNames}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

ButtonTW.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  // label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["primary", "secondary", "danger"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  disabled: PropTypes.bool,
};
