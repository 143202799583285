import { useParams } from "@reach/router";
import { useState, useRef, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { useDesigns } from "../../hooks/useDesigns";
import { useProject } from "../../hooks/useProject";
import { useTeam } from "../../hooks/useTeam";
import { sumNumbers } from "./Projects";
import { useReducer } from "react";
import { v4 } from "uuid";
import { api } from "../../util/api";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import { TextFieldTWSolo } from "../../comp/tw/TextField";
import { ButtonTW } from "../../comp/tw/Button";
import SaveIcon from "@mui/icons-material/Save";
import { enqueueSnackbar } from "notistack";

const Quote = ({ costPerFt, pricePerFtBeforeTax }) => {
  const { projectId } = useParams();
  const { designs, isLoading, error } = useDesigns();
  const {
    project,
    isLoading: projectIsLoading,
    error: projectError,
    mutate,
  } = useProject(projectId);
  const { teamIsLoading, team, error: errorLoading } = useTeam();
  // const [removal, setRemoval] = useState(false);
  const timeoutRef = useRef(null);

  if (isLoading || projectIsLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || projectError || errorLoading) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }
  const design = designs.find((design) => design.id === project.designId);

  const totalCost =
    sumNumbers(Object.values(project.lengths)) * pricePerFtBeforeTax;
  const lengths = sumNumbers(Object.values(project.lengths));

  // let removalCost = 0;
  // if (removal) {
  //   removalCost = team.team.removalCostPerFt * lengths;
  // }

  const extrasCost = sumNumbers(
    project?.extras?.map((e) => e.price * e.quantity) || []
  );
  // console.log(extrasCost);

  const subtotal = totalCost ? totalCost + extrasCost : extrasCost;
  // console.log(subtotal);
  const tax = subtotal * (team.team.stateTax / 100 + 1) - subtotal;

  return (
    <>
      <Extras />
      <div className="my-4">
        <TextFieldTWSolo
          type="number"
          value={project.adjustCost}
          label="Cost Adjustment Per Ft"
          onChange={(e) => {
            mutate(
              { ...project, adjustCost: e.target.value },
              {
                optimisticData: { ...project, adjustCost: e.target.value },
                revalidate: false,
              }
            );

            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
              api("PUT", `/project/${projectId}`, {
                adjustCost: e.target.value,
              });
              // mutate();
              // Perform other actions here, like making API calls or updating state
            }, 500);

            // }, 2);
            // dispatch({
            //   type: "updateItem",
            //   payload: { id: extra.id, item: e.target.value },
            // });
          }}
        />
      </div>

      <A4Estimate
        project={project}
        tax={tax}
        subtotal={subtotal}
        team={team}
        design={design}
        pricePerFtBeforeTax={pricePerFtBeforeTax}
        lengths={lengths}
      />

      {/* <Card>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={team.team.logo} style={{ width: "35%" }} />
            <h3>{team.team.website}</h3>
          </div>
          <div>
            <h3>Estimate:</h3>
            <p>{new Date().toDateString()}</p>
            <h5>Prepared For:</h5>
            <div>
              <p>
                {project.clientFirstName} {project.clientLastName}
              </p>
              <p>{project.clientPhone}</p>
              <p>{project.address}</p>
              <p>{project.city}</p>
            </div>
            <div>
              <img
                src={design.image}
                style={{ width: "100%", maxWidth: "400px" }}
              />
            </div>
            <Grid container>
              <Grid item xs={12}>
                <p>{design.name} </p>
              </Grid>
              <Grid item xs={6}>
                <p>
                  ${pricePerFtBeforeTax} x {lengths}ft
                </p>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <p>${totalCost.toFixed(2)}</p>
              </Grid>
            </Grid>

            {project.extras?.length
              ? project.extras.map((extra, key) => {
                  return (
                    <Grid key={`${key}-extras`} container>
                      <Grid item xs={12}>
                        <p>{extra.item} </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>
                          ${extra.price} x {extra.quantity}
                        </p>
                      </Grid>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={2}>
                        <p>${(extra.price * extra.quantity).toFixed(2)}</p>
                      </Grid>
                    </Grid>
                  );
                })
              : null}

            {removal ? (
              <Grid container>
                <Grid item xs={12}>
                  <p>Removal and Disposal </p>
                </Grid>
                <Grid item xs={6}>
                  <p>
                    ${team.team.removalCostPerFt} x {lengths}ft
                  </p>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}>
                  <p>${removalCost.toFixed(2)}</p>
                </Grid>
              </Grid>
            ) : null}

            <Grid container>
              <Grid item xs={3}></Grid>

              <Grid item xs={3}>
                <p>Subtotal: </p>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <p>${subtotal.toFixed(2)}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3}></Grid>

              <Grid item xs={3}>
                <p>
                  {team.team.stateTax}% {team.team.state} Tax:
                </p>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <p>${tax.toFixed(2)}</p>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card> */}
    </>
  );
};

const A4Estimate = ({
  project,
  subtotal,
  tax,
  team,
  design,
  pricePerFtBeforeTax,
  lengths,
}) => {
  const [imageData, setImageData] = useState(null);
  const [designImage, setDesignImage] = useState(null);

  useEffect(() => {
    const fetchImage = async (url, set) => {
      fetch(url)
        .then((response) => {
          // console.log(response);
          return response.blob();
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            // console.log(reader);
            const base64String = reader.result;
            set(base64String);
            // Use the base64String to create an image element or pass it to another function
          };
        })
        .catch((error) => {
          console.error("Error fetching image:", error);
        });
    };

    if (team.team.logo) {
      fetchImage(team.team.logo, setImageData);
    }

    if (design.image) {
      fetchImage(design.image, setDesignImage);
    }
  }, [team.team.logo, design.image]);

  const pdfRef = useRef();

  const generatePDF = () => {
    enqueueSnackbar("Downloading...");

    const input = pdfRef.current;

    html2canvas(input, {
      scale: 2, // Higher scale for better resolution
      useCORS: false, // Enable cross-origin images
      logging: true, // Reduce console noise
      allowTaint: true, // Allows cross-origin images to be rendered into the canvas
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgRatio = canvas.width / canvas.height;
      let imgWidth, imgHeight;

      if (imgRatio > pageWidth / pageHeight) {
        // Image is wider than the page, adjust width
        imgWidth = pageWidth;
        imgHeight = imgWidth / imgRatio;
      } else {
        // Image is taller than the page, adjust height
        imgHeight = pageHeight;
        imgWidth = imgHeight * imgRatio;
      }

      const x = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
      const y = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

      pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
      pdf.save(
        `${team.team.name} Estimate ${
          project.clientFirstName
            ? `- ${project.clientFirstName} ${project.clientLastName}`
            : ""
        }.pdf`
      );

      enqueueSnackbar("Downloaded...", { variant: "success" });
    });
  };

  // console.log(project);
  return (
    <>
      <ButtonTW onClick={generatePDF} label={"Download as PDF"} />

      <div
        ref={pdfRef}
        className="bg-white p-5 md:p-10 mx-auto my-10 border border-gray-300 shadow-md max-w-[210mm] max-h-[600mm] text-gray-800"
      >
        {/* Header */}
        <header className="mb-10">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold">
              Quote
              {/* #{project.clientFirstName.substring(0, 1)}
          {project.id.substring(0, 4).toUpperCase()}
          {project.clientLastName.substring(0, 1)} */}
            </h1>
            {imageData && <img src={imageData} alt="logo" className="w-24" />}
          </div>

          <p className="text-lg">{team.team.name}</p>
          <p className="text-sm">
            Prepared For: {project.clientFirstName} {project.clientLastName}
          </p>
          <p className="text-sm">{project.address}</p>
          <p className="text-sm">
            {project.city}, {project.state}
          </p>
          <p className="text-sm">Date: {new Date().toDateString()}</p>
        </header>

        {/* Project Details */}
        {project.notes && (
          <section className="mb-10">
            <h2 className="text-xl font-semibold mb-2">Project Details</h2>
            <p>{project.notes}</p>
          </section>
        )}

        {designImage ? (
          <img src={designImage} className="h-60" alt="designImage" />
        ) : null}

        {/* Line Items Table */}
        <section className="mb-10">
          <h2 className="text-xl font-semibold mb-2">Line Items</h2>
          <div className="grid grid-cols-5">
            <p className="border-y-4 py-2 text-left col-span-2">Item</p>
            <p className="border-y-4 py-2 text-left">Quantity</p>
            <p className="border-y-4 py-2 text-right">Price</p>
            <p className="border-y-4 py-2 text-right">Total</p>
            {lengths ? (
              <>
                <p className="py-2 col-span-2">{design.name}</p>
                <p className="py-2">{lengths}ft</p>
                <p className="py-2 text-right">{pricePerFtBeforeTax}</p>
                <p className="py-2 text-right">
                  $
                  {(
                    parseFloat(pricePerFtBeforeTax) * parseFloat(lengths)
                  ).toFixed(2)}
                </p>
              </>
            ) : null}
            {project.extras &&
              project.extras.map((item, idx) => (
                <>
                  <p key={`${idx}-extras-i`} className="py-2 col-span-2">
                    {item.item}
                  </p>
                  <p key={`${idx}-extras-q`} className="py-2">
                    {item.quantity}
                  </p>
                  <p key={`${idx}-extras-p`} className="py-2 text-right">
                    ${parseFloat(item.price).toFixed(2)}
                  </p>
                  <p key={`${idx}-extras-t`} className="py-2 text-right">
                    $
                    {(
                      parseFloat(item.price) * parseFloat(item.quantity)
                    ).toFixed(2)}
                  </p>
                </>
              ))}
          </div>
        </section>

        {/* Totals */}
        <section className="mt-10">
          <div className="flex justify-between font-semibold text-lg">
            <span>Subtotal:</span>
            <span>${subtotal.toFixed(2)}</span>
          </div>
          <div className="flex justify-between">
            <span>Tax:</span>
            <span>${tax.toFixed(2)}</span>
          </div>
          <div className="flex justify-between font-bold text-xl mt-4">
            <span>Total:</span>
            <span>${(subtotal + tax).toFixed(2)}</span>
          </div>
        </section>

        {/* Footer */}
        <footer className="mt-20 text-sm text-gray-600">
          <p>Thank you for your business!</p>
          <p>Please contact us with any questions regarding this estimate.</p>
        </footer>
      </div>
    </>
  );
};

// const initState = [{ id: "1", item: "", price: "", quantity: "" }];

function reducer(state, action) {
  if (action.type === "addBlank") {
    return [...state, { id: v4(), item: "", price: "", quantity: "" }];
  }
  if (action.type === "removeItem") {
    console.log(state[0].id, action.payload);
    return state.filter((item) => item.id !== action.payload);
  }

  if (action.type === "updateItem") {
    return state.map((item) => {
      if (item.id === action.payload.id) {
        return { ...item, ...action.payload };
      }
      return item;
    });
  }
}

const Extras = () => {
  const { projectId } = useParams();

  const {
    project,
    isLoading: projectIsLoading,
    error: projectError,
    mutate,
  } = useProject(projectId);
  const [extras, dispatch] = useReducer(reducer, project.extras || []);

  if (projectIsLoading) {
    return <div className="spinner" />;
  }

  if (projectError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const saveRow = () => {
    mutate(api("PUT", `/project/${projectId}`, { extras }), {
      optimisticData: { ...project, extras },
    });
  };

  return (
    <div>
      {extras.map((e, idx) => (
        <QuoteRow
          key={`${idx}-extras`}
          extra={e}
          dispatch={dispatch}
          project={project}
          mutate={mutate}
        />
      ))}
      <div>
        <ButtonTW
          variant="primary"
          label={<PlaylistAddIcon />}
          onClick={() => dispatch({ type: "addBlank" })}
          className="w-24"
        />
        <ButtonTW
          variant="secondary"
          className="ml-2 w-24"
          label={<SaveIcon />}
          onClick={saveRow}
        />
      </div>
    </div>
  );
};

const QuoteRow = ({ extra, dispatch, mutate, project }) => {
  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-10 my-2 items-center gap-4">
        <TextFieldTWSolo
          value={extra.item}
          label="Item"
          onChange={(e) => {
            dispatch({
              type: "updateItem",
              payload: { id: extra.id, item: e.target.value },
            });
          }}
          className="md:col-span-3"
        />
        <TextFieldTWSolo
          value={extra.price}
          label="Price ($)"
          onChange={(e) => {
            dispatch({
              type: "updateItem",
              payload: { id: extra.id, price: e.target.value },
            });
          }}
          className="md:col-span-3"
        />
        <TextFieldTWSolo
          value={extra.quantity}
          label="Quantity"
          onChange={(e) => {
            dispatch({
              type: "updateItem",
              payload: { id: extra.id, quantity: e.target.value },
            });
          }}
          className="md:col-span-3"
        />
        <ButtonTW
          label={<PlaylistRemoveIcon />}
          variant="danger"
          // size="small"
          onClick={() => {
            // api("PUT")
            console.log(extra.id);
            dispatch({ type: "removeItem", payload: extra.id });
          }}
          className="md:col-span-1"
        />
      </div>
    </div>
  );
};

export default Quote;
