import { ButtonTW } from "./Button";

export const ModalTW = ({ isOpen, onClose, text, confirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[10000] flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white rounded-lg shadow-lg w-96">
        {/* Header */}
        <div className="flex items-center justify-between px-4 py-2 border-b">
          <h3 className="text-lg font-semibold">Confirmation</h3>
          <button
            className="text-gray-400 hover:text-gray-600"
            onClick={onClose}
          >
            &times;
          </button>
        </div>

        {/* Body */}
        <div className="p-4">
          <p className="text-sm text-gray-600">{text}</p>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-2 px-4 py-2 border-t">
          <ButtonTW onClick={onClose} label={"Cancel"} />

          <ButtonTW
            onClick={() => {
              confirm();
              onClose();
            }}
            variant="danger"
            label={"Confirm"}
          />
        </div>
      </div>
    </div>
  );
};
