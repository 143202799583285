import { useParams } from "@reach/router";
import { useProject } from "../../hooks/useProject";
import { useTeam } from "../../hooks/useTeam";
import * as Yup from "yup";
import { Form, Formik } from "formik";
// import { TextField } from "formik-mui";
import { api } from "../../util/api";
import { enqueueSnackbar } from "notistack";
import Save from "@mui/icons-material/Save";
import { stringNotRequired } from "../../util/form";
import TextFieldTW from "../../comp/tw/TextField";
import { ButtonTW } from "../../comp/tw/Button";

export const ClientFormAddress = () => {
  const { projectId } = useParams();
  const { project, isLoading, error, mutate } = useProject(projectId);

  const { team, isLoading: teamIsLoading, error: teamError } = useTeam();

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const saveClientButton = async (values) => {
    enqueueSnackbar("Saving Client Details");
    await api("PUT", `/project/${project.id}`, values);
    await mutate();
    enqueueSnackbar("Client Details Updated", { variant: "success" });
  };

  const {
    clientFirstName,
    clientLastName,
    clientEmail,
    clientPhone,
    address,
    city,
    state,
    country,
  } = project;

  // const customerKeys = [
  //   "clientFirstName",
  //   "clientLastName",
  //   "clientEmail",
  //   "clientPhone",
  //   "address",
  //   "city",
  //   "state",
  //   "country",
  // ];

  return (
    <Formik
      initialValues={{
        clientFirstName,
        clientLastName,
        clientEmail,
        clientPhone,
        address,
        city,
        state,
        country,
      }}
      validationSchema={Yup.object().shape({
        clientFirstName: stringNotRequired,
        clientLastName: stringNotRequired,
        clientEmail: Yup.string().email("Invalid email").notRequired(),
        clientPhone: stringNotRequired,
        address: stringNotRequired,
        city: stringNotRequired,
        state: stringNotRequired,
        country: stringNotRequired,
      })}
      onSubmit={saveClientButton}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <TextFieldTW
              label="First Name"
              name="clientFirstName"
              type="text"
            />
            <TextFieldTW label="Last Name:" name="clientLastName" type="text" />
            <TextFieldTW label="Email:" name="clientEmail" type="email" />
            <TextFieldTW label="Phone:" name="clientPhone" type="text" />
            <TextFieldTW label="Address:" name="address" type="text" />
            <TextFieldTW label="City:" name="city" type="text" />
            <TextFieldTW label="State:" name="state" type="text" />
            <TextFieldTW label="Country:" name="country" type="text" />
          </div>
          {team.permissions.includes("editProject") ? (
            <ButtonTW type="submit" label={<Save />} />
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

// <div>
//   {isSubmitting ? (
//     <div className="spinner" />
//   ) : team.permissions.includes("editProject") ? (
//     <Button
//       variant="contained"
//       color="primary"
//       disabled={isSubmitting}
//       onClick={submitForm}
//     >
//       <Save />
//     </Button>
//   ) : null}
// </div>;
