import { useParams } from "@reach/router";
import { useProject } from "../../hooks/useProject";
import { useTeam } from "../../hooks/useTeam";
import { useDesign } from "../../hooks/useDesign";
import { v4 } from "uuid";
import { getSections } from "../../util/fence";
import { TextFieldTWSolo } from "../../comp/tw/TextField";

function decimalToMixedNumber(decimal) {
  const feet = Math.floor(decimal);
  const inches = Math.floor((decimal - feet) * 12);
  let numerator = inches;
  let denominator = 12;

  // // Simplify the fraction to its lowest terms
  // for (let i = numerator; i > 1; i--) {
  //   if (numerator % i === 0 && denominator % i === 0) {
  //     numerator /= i;
  //     denominator /= i;
  //   }
  // }

  // If the numerator is zero, return the feet only
  if (numerator === 0) {
    return feet.toString() + "ft";
  }

  // If the feet is zero, return the fraction only
  if (feet === 0) {
    return numerator.toString() + "/" + denominator.toString();
  }

  // Return the mixed number format in feet and inches
  return `${feet.toString() + "'-" + inches.toString() + `"`}`;
}

export const Materials = () => {
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const { design, isLoading, error } = useDesign(project.designId);
  const { teamIsLoading, team } = useTeam();

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (
    error?.response?.data === "Design is not in team" &&
    !team.permissions.includes("editProject")
  ) {
    return <p>Have an owner or quoter change design</p>;
  }

  if (error?.response?.data === "Design is not in team") {
    return <p>Select a new Design</p>;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  let numberOfPanelsPerLengthCalc = Object.values(project.lengths || []).map(
    (s) => getSections(parseFloat(s), parseFloat(design.lengthOfSection || 1))
  );
  let sizeOfPanelsCalc = numberOfPanelsPerLengthCalc.map((s, idx) => {
    return (Object.values(project.lengths || [])[idx] / s).toFixed(2);
  });

  const totalSections = numberOfPanelsPerLengthCalc.reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  let totalPosts = numberOfPanelsPerLengthCalc.reduce(
    (partialSum, a) => partialSum + a + 1,
    0
  );

  totalPosts = totalPosts - (project?.sharedPosts || 0);

  const totalCalc = () => {
    let total = 0;
    design.materials.forEach((m) => {
      if (m.fenceCategory === "post") {
        total += m.price * totalPosts * m.quantity;
      } else {
        total += m.price * totalSections * m.quantity;
      }
    });
    return total;
  };

  const total = totalCalc();
  const plusTax = (total * parseFloat(team.team.stateTax / 100 + 1)).toFixed(2);
  // const gridSplit = team.tierLimits.seePrices ? 4 : 6;
  // console.log(sizeOfPanelsCalc);
  return (
    <div className="my-4">
      {/* <h1>Materials</h1> */}
      {project.lengths &&
        Object.values(project.lengths || []).map((l, idx) => (
          <div key={v4()} className="grid grid-cols-2">
            <TextFieldTWSolo
              key={v4()}
              label={"Number of Sections"}
              value={numberOfPanelsPerLengthCalc[idx]}
              // disabled
              className="mx-4"
              readOnly
            />
            <TextFieldTWSolo
              key={v4()}
              label={"Size Of Panels"}
              value={decimalToMixedNumber(sizeOfPanelsCalc[idx])}
              className="mx-4"
              readOnly

              // disabled
            />
          </div>
        ))}

      {team.permissions.includes("getMaterials") &&
        design.materials
          .sort((a, b) => (a.material < b.material ? -1 : 1))
          .map((m, idx) => {
            return (
              <div
                key={`${idx} - materials`}
                className="text-lg md:text-xl grid grid-cols-3 md:grid-cols-4 border k p-2"
              >
                <p className="col-span-3 md:col-span-1 font-medium">
                  {m.material}
                </p>
                <p>
                  {m.fenceCategory === "post"
                    ? m.quantity * totalPosts
                    : m.quantity * totalSections}
                </p>
                {team.tierLimits.seePrices ? <p>${m.price}</p> : null}
                <p>
                  $
                  {m.fenceCategory === "post"
                    ? (m.quantity * totalPosts * m.price).toFixed(2)
                    : (m.quantity * totalSections * m.price).toFixed(2)}
                </p>
              </div>
            );
          })}
      {team.tierLimits.seePrices &&
      team.permissions.includes("seeMaterialCost") ? (
        <div className="my-2">
          <p className="text-xl">Material Cost: ${total.toFixed(2)}</p>
          <p className="text-xl">Plus Tax: ${plusTax}</p>
        </div>
      ) : null}
    </div>
  );
};
