import React, { useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  Polyline,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Button, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { useProject } from "./hooks/useProject";
import { useParams } from "@reach/router";
import { api } from "./util/api";
import { useTeam } from "./hooks/useTeam";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { enqueueSnackbar } from "notistack";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";
import FlagIcon from "@mui/icons-material/Flag";

const polylineUpdate = (polylines, sharedPosts) => {
  // Initialize arrays to store lengths and shared posts for each polyline
  let lengths = [];
  sharedPosts = 0;
  // console.log(polylines);

  // Loop through each polyline and calculate its lengths and shared posts

  polylines.forEach((poly) => {
    if (poly.length) {
      const polylineLengths = distances(poly); // Calculate distances for the current polyline
      lengths = [...lengths, ...(polylineLengths || [])];
      // console.log(lengths);
      sharedPosts = sharedPosts + (poly.length - 2);
    }
    // console.log(sharedPosts);

    // Calculate shared posts for the current polyline
  });

  if (sharedPosts < 0) {
    sharedPosts = 0;
  }

  return { lengths, sharedPosts };
};

function Menu({ mutate, project }) {
  const [draggable, setDraggable] = useState(true);
  const [run, setRun] = useState(0);
  const markerRef = React.useRef(null);

  const selectRun = () => {
    setRun((prevCount) => (prevCount === 3 ? 0 : prevCount + 1));
  };

  const toggleDraggable = React.useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  const setPost = async () => {
    const marker = markerRef.current;
    // enqueueSnackbar("Marker Placed");

    // Clone the existing polylines to avoid direct mutation
    const updatedPolylines = [...(project.polylines || [])];

    // Ensure that `updatedPolylines[run]` is initialized as an array if it doesn't exist
    if (!updatedPolylines[run]) {
      updatedPolylines[run] = []; // Initialize the polyline array for this `run` index
    }

    // Add the new marker to the specific polyline at the `run` index
    updatedPolylines[run] = [...updatedPolylines[run], marker.getLatLng()];

    if (updatedPolylines[run].length === 1) {
      enqueueSnackbar("New fence run started, Place your next marker", {
        variant: "success",
      });
    }
    // Update lengths and shared posts for this specific polyline
    const { lengths, sharedPosts } = polylineUpdate(updatedPolylines);

    const newProjectDetails = {
      polylines: updatedPolylines,
      lengths, // Adjust to track lengths separately per polyline if needed
      mapCenter: marker.getLatLng(),
      sharedPosts,
      position: marker.getLatLng(),
    };

    await mutate(api("PUT", `/project/${project.id}`, newProjectDetails), {
      optimisticData: {
        ...project,
        ...newProjectDetails,
      },
    });
  };
  const wrongButton = async () => {
    // Clone the existing polylines and lengths to avoid direct mutation
    const updatedPolylines = [...project.polylines];

    // Remove the last marker from the specific polyline based on `run`
    if (updatedPolylines[run]) {
      updatedPolylines[run] = updatedPolylines[run].slice(0, -1);
    }

    // Recalculate all polylines' lengths and shared posts
    const { lengths, sharedPosts } = polylineUpdate(updatedPolylines);

    await mutate(
      api("PUT", `/project/${project.id}`, {
        polylines: updatedPolylines,
        lengths,
        sharedPosts,
      }),
      {
        optimisticData: {
          ...project,
          polylines: updatedPolylines,
          lengths,
          sharedPosts,
        },
      }
    );
  };

  // console.log(distancesArray);
  return (
    <>
      <IconButton
        color="primary"
        style={{ zIndex: 9999 }}
        variant="contained"
        onClick={wrongButton}
      >
        <WrongLocationIcon />
      </IconButton>
      <IconButton
        color="primary"
        style={{ zIndex: 9999 }}
        onClick={setPost}
        variant="contained"
      >
        <AddLocationAltIcon />
      </IconButton>
      <IconButton
        style={{ zIndex: 9999 }}
        onClick={selectRun}
        variant="contained"
      >
        <FlagIcon
          className={`${
            run === 0
              ? "text-white"
              : run === 1
              ? "text-green-500"
              : run === 2
              ? "text-purple-500"
              : "text-yellow-500"
          }`}
        />
      </IconButton>
      <Marker
        draggable={draggable}
        position={project.position || [0, 0]}
        ref={markerRef}
        icon={icon}
      >
        <Popup minWidth={90}>
          <Button
            variant={draggable ? "contained" : null}
            onClick={toggleDraggable}
          >
            {draggable ? "draggable" : "locked"}
          </Button>
        </Popup>

        {project.polylines &&
          project.polylines.length &&
          project.polylines.map(
            (pls, idx) =>
              pls && (
                <Polyline
                  key={`${idx}-polyline`}
                  pathOptions={{
                    color:
                      idx === 0
                        ? "white"
                        : idx === 1
                        ? "lime"
                        : idx === 2
                        ? "purple"
                        : "yellow",
                  }}
                  positions={pls}
                />
              )
          )}
      </Marker>
    </>
  );
}

const MapWithMarkers = () => {
  const { projectId } = useParams();
  const { project = {}, isLoading, error, mutate } = useProject(projectId);
  const { isLoading: teamIsLoading, error: teamError, team } = useTeam();

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  return (
    <div className="px-3 lg:px-0">
      <MapContainer
        center={project.mapCenter || [0, 0]}
        dragging={true}
        // touchZoom={true}
        doubleClickZoom={false}
        zoom={project.mapCenter ? 20 : 15}
        zoomControl={false}
        scrollWheelZoom={true}
        style={{ height: "350px", zoom: "1.65", textAlign: "center" }}
      >
        <TileLayer
          url={`https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
          maxZoom={20}
          subdomains={["mt1", "mt2", "mt3"]}
        />{" "}
        <LocateMeButton
          address={project.address}
          project={project}
          mutate={mutate}
        />
        {team.permissions.includes("editProject") ? (
          <Menu mutate={mutate} project={project} />
        ) : null}
        <ZoomControl position="bottomleft" />
      </MapContainer>
    </div>
  );
};

const LocateMeButton = ({ project, mutate }) => {
  const map = useMapEvents({
    locationfound(e) {
      mutate({ ...project, position: e.latlng }, { revalidate: false });

      api("PUT", `/project/${project.id}`, {
        position: e.latlng,
      });
      // setPosition(e.latlng);
      map.flyTo(e.latlng, 18);
    },
  });
  const click = () => {
    map.locate({ enableHighAccuracy: true });
  };
  const callback = () => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${project.address}, ${project.city}, ${project.state}, ${project.country}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (!project.address) {
          enqueueSnackbar(
            "Project has no address, dont forget to save the project before you locate it",
            { variant: "info", autoHideDuration: 5000 }
          );
        }

        if (data.length === 0) {
          enqueueSnackbar(
            "Address not found try to be more specific, input coordinates into the address field if nothing else works",
            { variant: "info", autoHideDuration: 5000 }
          );
        } else {
          console.log(data);
          const coords = {
            lat: data.results[0].geometry.location.lat,
            lng: data.results[0].geometry.location.lng,
          };
          map.flyTo(
            {
              lat: data.results[0].geometry.location.lat,
              lng: data.results[0].geometry.location.lng,
            },
            18
          );
          mutate({ ...project, position: coords }, { revalidate: false });

          api("PUT", `/project/${project.id}`, {
            position: coords,
          });
        }
      });
  };

  return (
    <>
      <IconButton
        color="primary"
        style={{ zIndex: 9999 }}
        variant="contained"
        onClick={callback}
      >
        <SearchIcon />
      </IconButton>

      <IconButton
        color="primary"
        style={{ zIndex: 9999 }}
        variant="contained"
        onClick={click}
      >
        <GpsFixedIcon />
      </IconButton>
    </>
  );
};

const icon = new L.Icon({
  iconUrl: require("./close.png"),
  iconRetinaUrl: require("./close.png"),
  iconSize: [30, 30],
});

const distances = (polylines) => {
  try {
    if (polylines?.length > 0) {
      return polylines.reduce((acc, curr, index) => {
        if (index === 0) {
          return acc;
        }

        const dis = distance(
          curr.lat,
          curr.lng,
          polylines[index - 1].lat,
          polylines[index - 1].lng
        );

        return [...acc, dis.toFixed(0)];
      }, []);
    }
  } catch (e) {
    return [];
  }
};

function distance(lat1, lon1, lat2, lon2) {
  const earthRadius = 6371; // Earth's radius in kilometers
  const latDistance = ((lat2 - lat1) * Math.PI) / 180; // Convert to radians
  const lonDistance = ((lon2 - lon1) * Math.PI) / 180; // Convert to radians
  const a =
    Math.sin(latDistance / 2) * Math.sin(latDistance / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(lonDistance / 2) *
      Math.sin(lonDistance / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // convert kms to feet
  const distance = earthRadius * c * 3280.84; // Distance in feet
  return distance;
}

export default MapWithMarkers;
