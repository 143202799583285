import * as Yup from "yup";
import { Form, Formik } from "formik";

import { v4 } from "uuid";
import { useTeam } from "../../hooks/useTeam";
import { enqueueSnackbar } from "notistack";
import DesignConfig from "./Config";
import { useDesigns } from "../../hooks/useDesigns";
// import { useAuthenticator } from "@aws-amplify/ui-react";
// import SaveIcon from "@mui/icons-material/Save";
import { api } from "../../util/api";
import DeleteIcon from "@mui/icons-material/Delete";
import camelToTitle from "../../util/case";
import TextFieldTW, { TextFieldTWSolo } from "../../comp/tw/TextField";
import { ButtonTW } from "../../comp/tw/Button";

const decimalCleanUp = (target) => {
  target = target.replace(/[^0-9.]/g, "");

  // Ensure that there's only one decimal point
  const decimalCount = (target.match(/\./g) || []).length;
  if (decimalCount > 1) {
    target = target.slice(0, target.lastIndexOf("."));
  }
  return target;
};

const MaterialForm = ({ designPageState, dispatch, team, ...material }) => {
  // const [stateMaterial, setMaterial] = useState(material);

  // console.log(material);
  // const gridSplit = team.tierLimits.seePrices ? 4 : 6;
  return (
    <div className="border-solid border-zinc-300 rounded-lg border-4 p-2 my-2">
      <div className="grid grid-cols-2 lg:grid-cols-10 items-center gap-x-2">
        <TextFieldTWSolo
          label="Material Name:"
          value={material.material}
          onChange={(e) => {
            let mat = { ...material, material: e.target.value };
            dispatch({ type: "updateMaterial", payload: mat });
          }}
          className="col-span-2 lg:col-span-3"
        />
        {team?.tierLimits.seePrices ? (
          <TextFieldTWSolo
            label="Price ($):"
            value={material.price}
            onChange={(e) => {
              let mat = {
                ...material,
                price: decimalCleanUp(e.target.value),
              };
              // setMaterial(mat);
              dispatch({ type: "updateMaterial", payload: mat });
            }}
            className="lg:col-span-3"
          />
        ) : null}
        <TextFieldTWSolo
          label="Quantity:"
          value={material.quantity}
          onChange={(e) => {
            let mat = {
              ...material,
              quantity: decimalCleanUp(e.target.value),
            };
            // setMaterial(mat);
            dispatch({ type: "updateMaterial", payload: mat });
            // if (e.target.value === "0") {
            // }
          }}
          className="lg:col-span-3"
        />
        <div className="flex justify-end lg:justify-center col-span-2 lg:col-span-1">
          <ButtonTW
            label={<DeleteIcon />}
            variant="danger"
            onClick={() => {
              console.log("yoo");

              dispatch({ type: "removeMaterial", payload: material });
            }}
          />
        </div>
        {material.category === "other" ? (
          <div className="col-span-2 lg:col-span-9">
            <label
              htmlFor="othercategory"
              className="block text-sm font-medium text-gray-700"
            >
              Type:
            </label>
            <select
              id="othercategory"
              value={material.fenceCategory}
              onChange={(e) => {
                let mat = { ...material, fenceCategory: e.target.value };
                // setMaterial(mat);
                dispatch({ type: "updateMaterial", payload: mat });
              }}
              className="mt-1 p-3 w-full rounded-md border border-gray-300 "
            >
              <option key={"post"} value={"post"}>
                Post
              </option>
              <option key={"panel"} value={"panel"}>
                Panel
              </option>
            </select>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const postCategories = ["post", "concrete"];
const panelCategories = ["rail", "board", "other"];

const AddMaterialForm = ({ dispatch }) => {
  const { team, isLoading, error } = useTeam();
  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const saveButton = (values, actions) => {
    console.log(values);
    actions.setSubmitting(false);
    // return;
    actions.resetForm();

    if (values.category !== "other") {
      if (postCategories.includes(values.category)) {
        values.fenceCategory = "post";
      }
      if (panelCategories.includes(values.category)) {
        values.fenceCategory = "panel";
      }
    }

    dispatch({ type: "addMaterial", payload: { ...values, id: v4() } });
    enqueueSnackbar("Material Added", { variant: "success" });
  };
  // id: v4(),
  // material: "PT 4x4x10",
  // category: "post",
  // price: 22.03,
  // quantity: 1,
  // fenceCategory: "post",
  // weight: 38.5,

  const selectCategories = ["post", "concrete", "board", "rail", "other"];
  return (
    <div>
      <h3 className="text-2xl font-semibold">Create a Material</h3>
      <Formik
        initialValues={{
          material: "",
          category: "post",
          price: "",
          quantity: "",
          fenceCategory: "post",
          // weight: "",
        }}
        validationSchema={Yup.object().shape({
          material: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
          category: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
          price: Yup.number("Must be Number"),
          quantity: Yup.number("Must be Number").required("Required"),
          // weight: Yup.number("Must be Number").required("Required"),
          fenceCategory: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        })}
        onSubmit={saveButton}
      >
        {({
          submitForm,
          isSubmitting,
          isValid,
          errors,
          values,
          handleChange,
        }) => (
          <Form>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div
                className={`${values.category === "other" ? "" : "col-span-2"}`}
              >
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type:
                </label>
                <select
                  id="category"
                  value={values.category}
                  onChange={handleChange}
                  className="mt-1 p-3 w-full rounded-md border border-gray-300 "
                >
                  {selectCategories.map((mat) => (
                    <option key={mat} value={mat}>
                      {camelToTitle(mat)}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                {values.category === "other" ? (
                  <div
                  // className={`${
                  //   values.category === "other" ? "col-span-2" : ""
                  // }`}
                  >
                    <label
                      htmlFor="fenceCategory"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Type Category:
                    </label>
                    <select
                      id="fenceCategory"
                      value={values.fenceCategory}
                      onChange={handleChange}
                      className="mt-1 p-3 w-full rounded-md border border-gray-300 "
                    >
                      <option key={"post"} value={"post"}>
                        Post
                      </option>

                      <option key={"panel"} value={"panel"}>
                        Panel
                      </option>
                    </select>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-x-1">
              <div className="col-span-2 md:col-span-1">
                <TextFieldTW name="material" label="Name" />
              </div>
              {team.team.tier === "small" || team.team.tier === "enterprise" ? (
                <TextFieldTW name="price" label="Price ($)" />
              ) : null}
              <TextFieldTW
                name="quantity"
                label="Quantity"
                // isDisabled={team.team.tier === "contractor"}
              />
            </div>

            <div
              style={{
                textAlign: "right",
              }}
            >
              {isSubmitting ? (
                <div className="spinner" />
              ) : (
                <ButtonTW
                  variant="primary"
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                  label={"Add Material to Design"}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const MaterialList = ({ designPageState, dispatch }) => {
  const { designs, isLoading, error, mutate } = useDesigns();
  const { team, isLoading: teamIsLoading, error: teamError } = useTeam();

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const saveButton = async () => {
    if (designs.map((d) => d.name).includes(designPageState.name)) {
      enqueueSnackbar("Design name already exists", { variant: "error" });
      return;
    }
    if (designPageState.name === "") {
      enqueueSnackbar("Please name your design", { variant: "error" });
      return;
    }

    if (designPageState.lengthOfSection === "") {
      enqueueSnackbar("Please set a panel length", { variant: "error" });
      return;
    }

    if (designPageState.materials.length === 0) {
      enqueueSnackbar("Design has no materials", { variant: "error" });
      return;
    }
    // console.log(designPageState);
    enqueueSnackbar("Saving Design");

    await api("POST", `/team/design`, designPageState);
    await mutate();

    dispatch({ type: "resetDesign" });

    enqueueSnackbar("Design Saved", { variant: "success" });
  };

  designPageState.materials = designPageState.materials.sort(
    (a, b) => a.material - b.material
  );

  const rails = designPageState.materials.filter(
    (material) => material.category === "rail"
  );
  const post = designPageState.materials.filter(
    (material) => material.category === "post"
  );
  const concrete = designPageState.materials.filter(
    (material) => material.category === "concrete"
  );
  const boards = designPageState.materials.filter(
    (material) => material.category === "board"
  );
  const other = designPageState.materials.filter(
    (material) => material.category === "other"
  );

  return (
    <div>
      <DesignConfig designPageState={designPageState} dispatch={dispatch} />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Material List</h2>
      </div> */}

      <AddMaterialForm dispatch={dispatch} />

      <h3 className="text-2xl font-semibold">Rails</h3>
      {rails.map((material, idx) => {
        return (
          <MaterialForm
            designPageState={designPageState}
            dispatch={dispatch}
            team={team}
            {...material}
            key={`material-rail-${idx}`}
          />
        );
      })}
      <h3 className="text-2xl font-semibold">Posts</h3>
      {post.map((material, idx) => {
        return (
          <MaterialForm
            designPageState={designPageState}
            dispatch={dispatch}
            team={team}
            {...material}
            key={`material-post-${idx}`}
          />
        );
      })}
      <h3 className="text-2xl font-semibold">Concrete</h3>
      {concrete.map((material, idx) => {
        return (
          <MaterialForm
            designPageState={designPageState}
            dispatch={dispatch}
            team={team}
            {...material}
            key={`material-concrete-${idx}`}
          />
        );
      })}
      <h3 className="text-2xl font-semibold">Boards</h3>
      {boards.map((material, idx) => {
        return (
          <MaterialForm
            designPageState={designPageState}
            dispatch={dispatch}
            team={team}
            {...material}
            key={`material-boards-${idx}`}
          />
        );
      })}
      <h3 className="text-2xl font-semibold">Other</h3>
      {other.map((material, idx) => {
        return (
          <MaterialForm
            designPageState={designPageState}
            dispatch={dispatch}
            team={team}
            {...material}
            key={`material-other-${idx}`}
          />
        );
      })}

      {team.tierLimits.designs <= designs.length ? (
        <p>No More Designs Allowed</p>
      ) : (
        <ButtonTW
          label={"Save Design"}
          variant="primary"
          onClick={saveButton}
        />
      )}
    </div>
  );
};

export default MaterialList;
