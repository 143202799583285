import React from "react";
import { render } from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { SWRConfig } from "swr";
import { SnackbarProvider } from "notistack";
import { fetcher } from "./util/fetcher";
import { LocationProvider } from "@reach/router";
import * as Sentry from "@sentry/react";
import { Login } from "./Login";
// import mapFeature from "./mapFeature.png";

Sentry.init({
  dsn: "https://f15c3ae3311966d459ce909a91640756@o4504908276236288.ingest.us.sentry.io/4508311629856768",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://app.fencewiz.com",
    "https://stagingapp.fencewiz.com",
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

render(
  <React.StrictMode>
    <SnackbarProvider
      classes={{ containerRoot: "z-alert" }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={2500}
    >
      <LocationProvider>
        <Authenticator.Provider>
          <SWRConfig
            value={{
              revalidateIfStale: false,
              revalidateOnFocus: false,
              revalidateOnMount: true,
              refreshInterval: 0,
              fetcher,
            }}
          >
            <Login />
          </SWRConfig>
        </Authenticator.Provider>
      </LocationProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
