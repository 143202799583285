import { useAuthenticator } from "@aws-amplify/ui-react";
import { navigate, useNavigate } from "@reach/router";
import { Form, Formik } from "formik";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useTeam } from "../hooks/useTeam";
import { api } from "../util/api";
import * as Yup from "yup";
import { useState } from "react";
import { useSubscription } from "../hooks/useSubscription";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { ButtonTW } from "../comp/tw/Button";
import TextFieldTW from "../comp/tw/TextField";
import { ModalTW } from "../comp/tw/Modal";
// import { mutate } from "swr";

const Team = () => {
  const { team, isLoading, error } = useTeam();
  const navigate = useNavigate();

  const {
    subscription,
    isLoading: subLoading,
    error: subError,
  } = useSubscription(team.permissions.includes("getSubscription"));

  if (isLoading || subLoading) {
    return <div className="spinner" />;
  }

  if (error || subError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const addUserButton = () => {
    navigate("/team/add-user");
  };

  const createUserButton = () => {
    if (!team.permissions.includes("addUserToTeam")) {
      return null;
      // <p>Only Owners can Add Users</p>;
    }

    if (team.users.length >= team.tierLimits.users) {
      return <p>Max Users</p>;
    }

    return <ButtonTW label={"Add User"} onClick={addUserButton} />;
  };

  const teamConf = () => {
    if (team.team.tier === "contractor") {
      return <p>Subscribe to a higher tier to set prices</p>;
    }
    if (team.permissions.includes("editTeam")) {
      return (
        <>
          <TeamConfig />
        </>
      );
    }

    return <></>;
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-5xl">{team.team.name}</h1>

        {createUserButton()}
      </div>
      <br />

      <h2 className="font-bold text-3xl capitalize">{team.team.tier}</h2>
      <br />
      {subscription ? (
        <ButtonTW
          variant="primary"
          onClick={() => {
            window.location.href = subscription.url;
          }}
          label={"Edit Subscription"}
        />
      ) : null}
      {teamConf()}
      <div className="my-4">
        {" "}
        {team.users.map((user) => (
          <User key={user.id} user={user} team={team.team} />
        ))}
      </div>
    </div>
  );
};

const User = ({ user, team }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [areYouSure, setAreYouSure] = useState(false);
  const { user: localUser } = useAuthenticator((context) => [context.user]);
  const { mutate } = useTeam();

  const deleteUserButton = async () => setAreYouSure(true);

  const areYouSureButton = async () => {
    enqueueSnackbar("User Deleting...");
    await api("DELETE", `/team/user/${user.id}`);
    await mutate();
    enqueueSnackbar("User Deleted", { variant: "success" });
    navigate("/team");
  };
  // const editUserButton = () => {

  // }

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center text-lg border p-1">
      <h2 className="col-span-1">
        {user.firstName} {user.lastName}
      </h2>
      <h4 className="truncate">
        {localUser.username === user.id ? <u>{user.id}</u> : user.id}
      </h4>
      <h4 className="uppercase">{team.users[user.id]}</h4>
      {localUser.username !== user.id &&
      team.users[localUser.username] === "owner" &&
      areYouSure === false ? (
        <ButtonTW
          variant="danger"
          label={<DeleteIcon />}
          onClick={deleteUserButton}
          className="w-10"
        />
      ) : null}

      <ModalTW
        isOpen={areYouSure}
        text="Are you sure you want to delete this user?"
        confirm={areYouSureButton}
        onClose={() => setAreYouSure(false)}
      />
    </div>
  );
};

const TeamConfig = () => {
  const { team, isLoading, error, mutate } = useTeam();

  if (isLoading || !team) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const saveTeamButton = async (values) => {
    enqueueSnackbar("Saving Team Details");
    console.log(values);
    console.log(`/team/${team.team.id}`);
    await api("PUT", `/team/${team.team.id}`, values);
    mutate({ ...team, ...values });
    enqueueSnackbar("Client Details Updated", { variant: "success" });
  };

  const {
    stateTax,
    labourCostPerFt,
    materialMultiplier,
    // removalCostPerFt
  } = team.team;

  const customerKeys = [
    "stateTax",
    "labourCostPerFt",
    "materialMultiplier",
    // "removalCostPerFt",
  ];
  const customerKeysLabel = [
    "State Tax (%)",
    "Labor Cost per Ft ($)",
    "Material Markup (%)",
    // "Remove Cost per Ft ($)",
  ];

  return (
    <div>
      <LogoUpload team={team} mutate={mutate} />
      <Formik
        initialValues={{
          stateTax,
          labourCostPerFt,
          materialMultiplier,
        }}
        validationSchema={Yup.object().shape({
          stateTax: Yup.number().notRequired("Required"),
          labourCostPerFt: Yup.number().notRequired("Required"),
          materialMultiplier: Yup.number().notRequired("Required"),
        })}
        onSubmit={saveTeamButton}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 items-center">
              {customerKeys.map((projectKey, idx) => {
                return (
                  <TextFieldTW
                    key={`${idx}-team`}
                    // component={TextField}
                    name={projectKey}
                    label={customerKeysLabel[idx]}
                  />
                );
              })}
            </div>

            <div>
              {isSubmitting ? (
                <div className="spinner" />
              ) : (
                <ButtonTW
                  variant="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  label={<SaveIcon />}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const LogoUpload = ({ team, mutate }) => {
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    // Create a new FileReader instance
    // const reader = new FileReader();

    // Set the onload event handler for the reader
    // reader.onload = async () => {
    //   const newTeam = await api("PUT", `/team/${team.team.id}`, {
    //     logo: reader.result,
    //   });
    //   mutate({ ...team, team: newTeam }, { revalidate: false });
    //   // setImageBase64(reader.result);
    // };

    // Read the file as a base64-encoded string
    // reader.readAsDataURL(file);
    const reader = new FileReader();

    reader.onload = async (event) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const maxWidth = 2000;
        const maxHeight = 2000;

        let targetWidth;
        let targetHeight;

        if (img.width > maxWidth || img.height > maxHeight) {
          if (img.width > img.height) {
            targetWidth = maxWidth;
            targetHeight = maxWidth / aspectRatio;
          } else {
            targetHeight = maxHeight;
            targetWidth = maxHeight * aspectRatio;
          }
        } else {
          targetWidth = img.width;
          targetHeight = img.height;
        }

        // setWidth(targetWidth);
        // setHeight(targetHeight);

        createImageBitmap(img, {
          resizeWidth: targetWidth,
          resizeHeight: targetHeight,
        })
          .then(async (resizedBitmap) => {
            let quality = 0.99;
            let canvas = document.createElement("canvas");
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(resizedBitmap, 0, 0, targetWidth, targetHeight);
            let dataUrl = canvas.toDataURL("image/jpeg", quality);
            console.log(dataUrl.length);
            while (dataUrl.length > 1000000) {
              console.log(dataUrl.length);
              quality -= 0.01;
              dataUrl = canvas.toDataURL("image/jpeg", quality);
            }

            const newTeam = await api("PUT", `/team/${team.team.id}`, {
              logo: dataUrl,
            });
            mutate({ ...team, team: newTeam }, { revalidate: false });
          })
          .catch((e) => {
            console.log(e);
          });
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="my-4 flex justify-between">
      {team.team.logo && (
        <div>
          <img
            src={team.team.logo}
            alt={team.team.name}
            className="w-48 lg:w-96"
          />
        </div>
      )}

      <div>
        <label
          htmlFor="raised-button-file"
          className="transform hover:scale-105 hover:shadow-2xl px-3 py-2 inline-flex items-center justify-center font-semibold transition ease-in-out duration-200 rounded focus:outline-none focus:ring-4 text-white bg-gradient-to-r from-indigo-500 via-blue-500 to-indigo-500 hover:from-indigo-500 hover:via-purple-500 hover:to-indigo-500 shadow-lg shadow-indigo-500/50 focus:ring-indigo-400"
        >
          {team.team.logo ? "Update Logo" : "Upload Logo"}
          {/* {fileName ? `Selected: ${fileName}` : "Upload Image"} */}
        </label>

        <input
          type="file"
          accept="image/*"
          id="raised-button-file"
          onChange={handleImageChange}
          className="hidden"
        />
      </div>
    </div>
  );
};

export default Team;
