// Text.jsx
import React from "react";
import PropTypes from "prop-types";

const Text = ({ level = 1, text, size = "medium", isHeader = true }) => {
  const baseStyles = ` text-black`;

  const sizeStyles = {
    small: "font-bold text-l md:text-xl", // Small text size
    medium: "font-bold text-xl md:text-2xl", // Medium text size
    large: "font-bold text-2xl md:text-3xl", // Large text size
    xlarge: "font-bold text-4xl md:text-5xl", // Large text size

    regular: "text-base md:text-lg", // Regular text size
  };

  const Tag = isHeader ? `h${level}` : "p"; // Choose between header (h1, h2, etc.) or paragraph (p)

  return <Tag className={`${baseStyles} ${sizeStyles[size]}`}>{text}</Tag>;
};

Text.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large", "regular", "xlarge"]),
  isHeader: PropTypes.bool, // Determines if it's a header (h1, h2, etc.) or regular text (p)
};

export default Text;
