import HideImageIcon from "@mui/icons-material/HideImage";
import { TextFieldTWSolo } from "../../comp/tw/TextField";
import { ButtonTW } from "../../comp/tw/Button";
const DesignConfig = ({ designPageState, dispatch }) => {
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        // setOriginalImage(event.target.result);
        const aspectRatio = img.width / img.height;
        const maxWidth = 2000;
        const maxHeight = 2000;

        let targetWidth;
        let targetHeight;

        if (img.width > maxWidth || img.height > maxHeight) {
          if (img.width > img.height) {
            targetWidth = maxWidth;
            targetHeight = maxWidth / aspectRatio;
          } else {
            targetHeight = maxHeight;
            targetWidth = maxHeight * aspectRatio;
          }
        } else {
          targetWidth = img.width;
          targetHeight = img.height;
        }

        // setWidth(targetWidth);
        // setHeight(targetHeight);

        createImageBitmap(img, {
          resizeWidth: targetWidth,
          resizeHeight: targetHeight,
        }).then((resizedBitmap) => {
          let quality = 0.99;
          let canvas = document.createElement("canvas");
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(resizedBitmap, 0, 0, targetWidth, targetHeight);
          let dataUrl = canvas.toDataURL("image/jpeg", quality);

          while (dataUrl.length > 1000000) {
            quality -= 0.01;
            dataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          // console.log(dataUrl);
          dispatch({ type: "updateImage", payload: dataUrl });
          // setResizedImage(dataUrl);
        });
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  const removeImage = () => {
    dispatch({ type: "updateImage", payload: "" });
  };

  return (
    <div>
      <h2 className="text-3xl font-semibold mb-1">Panel Config </h2>

      <div className="grid grid-cols-4 items-center">
        <TextFieldTWSolo
          label="Design Name:"
          value={designPageState.name}
          onChange={(e) =>
            dispatch({ type: "updateDesignName", payload: e.target.value })
          }
          className="col-span-4 md:col-span-3"
        />
        <TextFieldTWSolo
          label="Length of Section (ft):"
          value={designPageState.lengthOfSection}
          onChange={(e) =>
            dispatch({ type: "updateLengthOfSection", payload: e.target.value })
          }
          className="col-span-2 md:col-span-1"
        />
      </div>

      <div>
        <div className="mb-4 flex justify-between">
          <div>
            <label
              htmlFor="raised-button-file"
              className="transform hover:scale-105 hover:shadow-2xl px-3 py-2 inline-flex items-center justify-center font-semibold transition ease-in-out duration-200 rounded focus:outline-none focus:ring-4 text-white bg-gradient-to-r from-indigo-500 via-blue-500 to-indigo-500 hover:from-indigo-500 hover:via-purple-500 hover:to-indigo-500 shadow-lg shadow-indigo-500/50 focus:ring-indigo-400"
            >
              {designPageState.image
                ? "Change Design Image"
                : "Upload Design Image"}
            </label>

            <input
              type="file"
              accept="image/*"
              id="raised-button-file"
              onChange={handleImageChange}
              className="hidden"
            />
          </div>
          {designPageState.image ? (
            <ButtonTW
              label={<HideImageIcon />}
              variant="danger"
              onClick={removeImage}
            />
          ) : null}
        </div>

        <div className="flex justify-center">
          {designPageState.image ? (
            <img
              src={designPageState.image}
              className="max-h-96"
              alt="designPicture"
              // style={{
              //   maxWidth: "35vw",
              // }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DesignConfig;
