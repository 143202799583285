import { useSettings } from "./hooks/useSettings";
import NewCompany from "./pages/NewCompany";
import { MainRouter } from "./Router";

export const Switch = () => {
  const { settings, error, isLoading } = useSettings();

  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  if (settings.sk === "no-team") {
    return <NewCompany />;
  }

  return <MainRouter />;
};
