import * as React from "react";

// import { Loading } from "../../App";
import { useNavigate } from "@reach/router";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Hub } from "aws-amplify";
import { useTeam } from "../hooks/useTeam";
import GroupIcon from "@mui/icons-material/Group";
import ConstructionIcon from "@mui/icons-material/Construction";
import HomeIcon from "@mui/icons-material/Home";
import { useSettings } from "../hooks/useSettings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from "@reach/router";

function ButtonAppBar() {
  // const { user, setUser } = React.useContext(UserContext);
  const { settings } = useSettings();

  const navigate = useNavigate();
  const location = useLocation();
  // const [open, setOpen] = React.useState(false);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const {
    team,
    // isLoading,
    //  error
  } = useTeam(settings?.sk === "no-team" ? false : true);

  React.useEffect(() => {
    Hub.listen("auth", (data) => {
      // console.log(event);
      switch (data.payload.event) {
        case "signOut":
          window.location.reload();
          break;
        default:
          break;
      }
    });
  }, []);
  // if (isLoading) {
  //   return <div className="spinner" />;
  // }

  // if (isPending) {
  //   return <div className="spinner" />;
  // }

  const signOutButton = () => {
    signOut();
    navigate("/");
  };

  return (
    <>
      <nav className="bg-black text-white py-1 lg:py-4">
        <div className="container mx-auto flex justify-between items-center">
          <span
            onClick={() => navigate("/")}
            className={`hover:bg-zinc-800 p-2 px-4 rounded-lg ${
              location.pathname === "/" && "bg-zinc-800"
            }`}
          >
            <HomeIcon />
          </span>{" "}
          {user?.attributes?.email && team?.permissions && (
            <span
              className={`hover:bg-zinc-800 p-2 px-4 rounded-lg ${
                location.pathname === "/team" && "bg-zinc-800"
              }`}
              onClick={() => navigate("/team")}
            >
              <GroupIcon />
            </span>
          )}{" "}
          {team?.permissions.includes("editDesign") && (
            <span
              onClick={() => navigate("/design")}
              className={`hover:bg-zinc-800 p-2 px-4 rounded-lg ${
                location.pathname === "/design" && "bg-zinc-800"
              }`}
            >
              <ConstructionIcon />
            </span>
          )}
          <span
            onClick={signOutButton}
            className="hover:bg-zinc-800 p-2 px-4 rounded-lg"
          >
            <LogoutIcon />
          </span>
        </div>
      </nav>
    </>
  );
}

// <AppBar position="static">
//   <Toolbar>
//     {/* <span onClick={() => navigate("/")} style={{ width: "100%" }}>
//               <img src={logo} style={{ color: "white", height: "5em" }} />
//             </span> */}

// {user?.attributes?.email && team?.permissions ? (
//   <span onClick={() => navigate("/")} style={{ width: "100%" }}>
//     <HomeIcon />
//   </span>
// ) : (
//   <span onClick={() => navigate("/")} style={{ width: "100%" }}>
//     <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//       FenceWiz.com
//     </Typography>
//   </span>
// )}
// {user?.attributes?.email && team?.permissions && (
//   <span onClick={() => navigate("/team")} style={{ width: "100%" }}>
//     <GroupIcon />
//   </span>
// )}
// {team?.permissions.includes("editDesign") && (
//   <span onClick={() => navigate("/design")} style={{ width: "100%" }}>
//     <ConstructionIcon />
//   </span>
// )}

// <Button color="inherit" onClick={signOutButton}>
//   <p style={{}}>{user?.attributes?.email && "Logout"}</p>
// </Button>
//   </Toolbar>
// </AppBar>;
export default ButtonAppBar;
