import { api } from "../util/api";
// import { useSnackbar } from "notistack";
import { useTiers } from "../hooks/useTier";
import Text from "../comp/tw/Text";
import { useState } from "react";
import { ButtonTW } from "../comp/tw/Button";

const FormContainer = () => {
  const [tier, setTier] = useState();
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const submitForm = async () => {
    const subscribe = await api("POST", "/team", {
      tier,
      companyName,
      website,
    });

    window.location.href = subscribe.url;
  };

  if (currentPage === 1) {
    return (
      <NewCompany
        handleNextPage={handleNextPage}
        setTier={setTier}
        tier={tier}
      />
    );
  }

  if (currentPage === 2) {
    return (
      <SimpleForm
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        setTier={setTier}
        companyName={companyName}
        setCompanyName={setCompanyName}
      />
    );
  }

  if (currentPage === 3) {
    return (
      <WebsiteName
        handleNextPage={submitForm}
        setWebsite={setWebsite}
        website={website}
        handlePrevPage={handlePrevPage}
      />
    );
  }

  return <div className="container mx-auto p-4"></div>;
};

function SimpleForm({
  handlePrevPage,
  handleNextPage,
  setCompanyName,
  companyName,
}) {
  const handleChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleSubmit = () => {
    if (companyName !== "") {
      handleNextPage();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen p-4 bg-gray-100">
      <div className="w-full max-w-md">
        <label htmlFor="input" className="block font-medium text-gray-700">
          Enter your Company Name:
        </label>
        <input
          type="text"
          placeholder="Enter text"
          value={companyName}
          onChange={handleChange}
          className="border border-gray-300 p-2 w-full mb-4"
        />
        <ButtonTW
          type="submit"
          label="Next Step"
          variant="primary"
          onClick={handleSubmit}
        />
         
      </div>
      <div className="absolute bottom-4 left-4 py-2 px-4 ">
        <ButtonTW variant="danger" label="Back" onClick={handlePrevPage} />
      </div>
    </div>
  );
}

function WebsiteName({ handlePrevPage, handleNextPage, website, setWebsite }) {
  const handleChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSubmit = () => {
    if (website !== "") {
      handleNextPage();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen p-4 bg-gray-100">
      <div className="w-full max-w-md">
        <label htmlFor="input" className="block font-medium text-gray-700">
          Enter your Website:
        </label>
        <input
          type="text"
          placeholder="Enter text"
          value={website}
          onChange={handleChange}
          className="border border-gray-300 p-2 w-full mb-4"
        />
        <ButtonTW
          type="submit"
          label="Next Step"
          variant="primary"
          onClick={handleSubmit}
        />
         
      </div>
      <div className="absolute bottom-4 left-4 py-2 px-4 ">
        <ButtonTW variant="danger" label="Back" onClick={handlePrevPage} />
      </div>
    </div>
  );
}

const NewCompany = ({ tier, handleNextPage, setTier }) => {
  // const { enqueueSnackbar } = useSnackbar();
  const { tiers, isLoading, error } = useTiers();
  const [selectedPlanId, setSelectedPlanId] = useState(tier || null);

  const handlePlanSelect = (plan) => {
    setTier(plan);
    handleNextPage();
    setSelectedPlanId(plan);
  };

  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const pricingData = [
    {
      title: "General Contractor",
      id: "contractor",
      price: tiers.contractor.cost,
      period: "Per Month",
      features: [
        "1 User",
        "1 Design",
        "Owner Role",
        "Get Total Material Quantities",
      ],
    },
    {
      title: "Small Business",
      id: "small",

      price: tiers.small.cost,
      period: "Per Month",
      features: [
        "3 Users",
        "3 Designs",
        "Owner Role",
        "Foreman Role",
        "Quoter Role",
        "Set Material Prices and Markup",
        "Set State Tax",
        "Set Labour per ft",
        "Customer Cost per ft",
        "Get Total Material Cost and Quantities",
      ],
    },
    {
      title: "Enterprise",
      price: tiers.enterprise.cost,
      id: "enterprise",

      period: "Per Month",
      features: [
        "10 Users",
        "100 Designs",
        "Owner Role",
        "Foreman Role",
        "Quoter Role",
        "Installer Role",
        "Set Material Prices and Markup",
        "Set State Tax",
        "Set Labour per ft",
        "Customer Cost per ft",
        "Get Total Material Cost and Quantities",
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
        <h1 className="text-center text-4xl font-bold mb-8">
          Welcome to FenceWiz
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {pricingData.map((plan) => (
            <div
              key={plan.title}
              className={`bg-white p-6 rounded-lg shadow-lg flex flex-col items-center h-full hover:shadow-blue-500 ${
                selectedPlanId === plan.id ? "border-blue-500 border-2" : ""
              }`}
              onClick={() => handlePlanSelect(plan.id)}
            >
              <Text isHeader size="large" text={plan.title} />
              <Text isHeader size="medium" text={`$${plan.price}`} />
              <Text isHeader size="regular" text={plan.period} />

              <ul className="mt-6 space-y-2 text-gray-700">
                {plan.features.map((feature, index) => (
                  <li key={index} className="text-center">
                    {feature}
                  </li>
                ))}
              </ul>
              <div className="flex justify-center mt-4 items-end h-full">
                <ButtonTW
                  label="Select Plan"
                  variant="primary"
                  onClick={() => handlePlanSelect(plan.title)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FormContainer;
//  <ButtonAppBar />
//       <Card>
//         <div>
//           <h1>Welcome to Fencewiz.com</h1>
//           <Grid container>
//             <Grid item xs={12} lg={4}>
//               <Card>
//                 <Grid container>
//                   <Grid item xs={6}>
//                     <div>
//                       <h2>General Contractor</h2>
//                       <div style={{ display: "flex", alignItems: "center" }}>
//                         <h2>${tiers.contractor.cost}</h2>
//                         <div>
//                           <p>
//                             <i> Per Month</i>
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <p>1 User</p>
//                     <p>1 Design</p>
//                     <p>Owner Role</p>
//                     <p>Get Total Material Quantities</p>
//                   </Grid>
//                 </Grid>
//               </Card>
//             </Grid>
//             <Grid item xs={12} lg={4}>
//               <Card>
//                 <Grid container>
//                   <Grid item xs={6}>
//                     <div>
//                       <h2>Small Business</h2>
//                       <div style={{ display: "flex", alignItems: "center" }}>
//                         <h2>${tiers.small.cost}</h2>

//                         <div>
//                           <p>
//                             <i> Per Month</i>
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <p>3 Users</p>
//                     <p>3 Designs</p>
//                     <p>Owner Role</p>
//                     <p>Foreman Role</p>
//                     <p>Quoter Role</p>
//                     <p>Set Material Prices and Markup </p>
//                     <p>Set State Tax </p>
//                     <p>Set Labour per ft</p>
//                     <p>Customer Cost per ft</p>
//                     <p>Get Total Material Cost and Quantities </p>
//                   </Grid>
//                 </Grid>
//               </Card>
//             </Grid>
//             <Grid item xs={12} lg={4}>
//               <Card>
//                 <Grid container>
//                   <Grid item xs={6}>
//                     <div>
//                       <h2>Enterprise</h2>
//                       <div style={{ display: "flex", alignItems: "center" }}>
//                         <h2>${tiers.enterprise.cost}</h2>
//                         <div>
//                           <p>
//                             <i> Per Month</i>
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <p>10 Users</p>
//                     <p>100 Designs</p>
//                     <p>Owner Role</p>
//                     <p>Foreman Role</p>
//                     <p>Quoter Role</p>
//                     <p>Installer Role</p>
//                     <p>Set Material Prices and Markup </p>
//                     <p>Set State Tax </p>
//                     <p>Set Labour per ft</p>
//                     <p>Customer Cost per ft</p>
//                     <p>Get Total Material Cost and Quantities </p>
//                   </Grid>
//                 </Grid>
//               </Card>
//             </Grid>
//           </Grid>
//           <h2></h2>

//           <Card>
//             <div>
//               <h3>Create New Company</h3>
//               <Formik
//                 initialValues={{
//                   companyName: "",
//                   website: "",
//                   tier: "",
//                 }}
//                 validationSchema={Yup.object().shape({
//                   companyName: Yup.string()
//                     .min(2, "Too Short!")
//                     .max(50, "Too Long!")
//                     .required("Required"),
//                   website: Yup.string()
//                     .min(2, "Too Short!")
//                     .max(50, "Too Long!")
//                     .required("Required"),
//                   tier: Yup.string().required("Required"),
//                 })}
//                 onSubmit={newCompany}
//               >
//                 {({ submitForm, isSubmitting }) => (
//                   <Form>
//                     <Grid container>
//                       <Grid item xs={12} md={6}>
//                         <Field
//                           component={TextField}
//                           name="companyName"
//                           // type="text"
//                           label="Company Name"
//                         />
//                       </Grid>
//                       <Grid item xs={12} md={6}>
//                         <Field
//                           component={TextField}
//                           name="website"
//                           // type="text"
//                           label="Website"
//                         />
//                       </Grid>
//                     </Grid>

//                     <h3>Tier:</h3>

//                     <Grid item xs={12} md={6}>
//                       <Field component={RadioGroup} name="tier">
//                         <FormControlLabel
//                           value="contractor"
//                           control={<Radio disabled={isSubmitting} />}
//                           label="One Time Contractor"
//                         />
//                         <FormControlLabel
//                           value="small"
//                           control={<Radio disabled={isSubmitting} />}
//                           label="New/Small Business"
//                           disabled={isSubmitting}
//                         />
//                         <FormControlLabel
//                           value="enterprise"
//                           control={<Radio disabled={isSubmitting} />}
//                           label="Enterprise Business"
//                           disabled={isSubmitting}
//                         />
//                       </Field>
//                     </Grid>

//                     <Grid item xs={12} md={6}>
//                       {isSubmitting ? (
//                         <div className="spinner" />
//                       ) : (
//                         <Button
//                           variant="contained"
//                           color="primary"
//                           disabled={isSubmitting}
//                           onClick={submitForm}
//                         >
//                           Subscribe
//                         </Button>
//                       )}
//                     </Grid>
//                   </Form>
//                 )}
//               </Formik>
//             </div>
//           </Card>
//           {/* <Grid item xs={12} md={6}>
//             <Card>
//               <div>
//                 <h2>Join Existing Company</h2>
//                 <Formik
//                   initialValues={{
//                     code: "",
//                   }}
//                   validationSchema={Yup.object().shape({
//                     code: Yup.string()
//                       .min(2, "Too Short!")
//                       .max(50, "Too Long!")
//                       .required("Required"),
//                   })}
//                   onSubmit={(values, { setSubmitting }) => {
//                     setTimeout(() => {
//                       setSubmitting(false);
//                       alert(JSON.stringify(values, null, 2));
//                     }, 500);
//                   }}
//                 >
//                   {({ submitForm, isSubmitting }) => (
//                     <Form>
//                       <Grid item xs={12} md={6}>
//                         <Field
//                           component={TextField}
//                           name="code"
//                           // type="text"
//                           label="Code"
//                         />
//                       </Grid>
//                       <Grid item xs={12} md={6}>
//                         {isSubmitting ? (
//                           <div className="spinner" />
//                         ) : (
//                           <Button
//                             variant="contained"
//                             color="primary"
//                             disabled={isSubmitting}
//                             onClick={submitForm}
//                           >
//                             Join
//                           </Button>
//                         )}
//                       </Grid>
//                     </Form>
//                   )}
//                 </Formik>
//               </div>
//             </Card>
//           </Grid> */}
//         </div>
//       </Card>
