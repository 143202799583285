import React from "react";
import { useField } from "formik";

export const TextFieldTWSolo = ({
  label,
  value,
  onChange,
  type = "text",
  disabled = false,
  className = "",
  ...inputProps
}) => {
  return (
    <div className={`mb-4 ${className}`}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <input
        disabled={disabled}
        type={type}
        value={value}
        onChange={onChange}
        className={`mt-1 p-3 w-full rounded-md border
          focus:outline-none focus:rin-2 focus:ring-blue-400 transition-all ease-in-out duration-200`}
        {...inputProps}
      />
    </div>
  );
};

const TextFieldTW = ({ label, className, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className={`mb-4 ${className}`}>
      <label
        htmlFor={props.id || props.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <input
        className={`mt-1 p-3 w-full rounded-md border ${
          meta.touched && meta.error ? "border-red-500" : "border-gray-300"
        } 
          focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all ease-in-out duration-200`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-red-500 text-xs mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const TextBoxTW = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="mb-4">
      <label
        htmlFor={props.id || props.name}
        className="block   
 text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <textarea
        className={`mt-1   
 p-3 w-full rounded-md border h-24 ${
   // Added h-24 for height
   meta.touched && meta.error ? "border-red-500" : "border-gray-300"
 } 
        focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all ease-in-out duration-200`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-red-500 text-xs mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default TextFieldTW;
