import { useReducer, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useTeam } from "../../hooks/useTeam";
import { api } from "../../util/api";
import { useDesigns } from "../../hooks/useDesigns";
import Picker from "./Picker";
import MaterialList from "./Materials";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ImageIcon from "@mui/icons-material/Image";
import { v4 } from "uuid";

import TextFieldTW from "../../comp/tw/TextField";
import { ButtonTW } from "../../comp/tw/Button";
import { useSWRConfig } from "swr";
import { ModalTW } from "../../comp/tw/Modal";

const defaultDesign = {
  materials: [],
  lengthOfSection: 8,
  name: "",
  image: "",
};

// const defaultDesign = {
//   materials: shadowBox.materials,
//   lengthOfSection: shadowBox.lengthOfSection,
//   name: shadowBox.name,
// };

function reducer(state, action) {
  if (action.type === "updateImage") {
    return {
      ...state,
      image: action.payload,
    };
  }

  if (action.type === "updateDesignName") {
    return {
      ...state,
      name: action.payload,
    };
  }

  if (action.type === "updateLengthOfSection") {
    return {
      ...state,
      lengthOfSection: action.payload,
    };
  }

  if (action.type === "addMaterial") {
    return {
      ...state,
      materials: [...state.materials, action.payload],
    };
  }

  if (action.type === "updateMaterial") {
    return {
      ...state,
      materials: state.materials.map((material) => {
        if (material.id === action.payload.id) {
          return action.payload;
        }
        return material;
      }),
    };
  }

  if (action.type === "removeMaterial") {
    return {
      ...state,
      materials: state.materials.filter(
        (material) => material.id !== action.payload.id
      ),
    };
  }

  if (action.type === "copyDesign") {
    return { ...defaultDesign, ...action.payload, id: v4() };
  }

  if (action.type === "resetDesign") {
    return defaultDesign;
  }

  throw Error("Unknown action.");
}

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <>{children}</>}
//     </div>
//   );
// }

const Design = () => {
  // const [tab, setTab] = useState(0);
  const [activeTab, setActiveTab] = useState(1);

  // Tab switcher function

  const [designPageState, dispatch] = useReducer(reducer, defaultDesign);
  const { isLoading, error, designs, mutate, isValidating } = useDesigns();

  const { isLoading: teamIsLoading, error: teamError } = useTeam();

  // const handleTabChange = (event, newValue) => {
  //   setTab(newValue);
  // };

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  if (isLoading || teamIsLoading || isValidating) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  return (
    <div className="w-full max-w mx-auto">
      {/* Tab Headers */}
      <div className="text-lg font-semibold flex border-b border-gray-300">
        <button
          className={`py-2 px-4 w-1/3 ${
            activeTab === 1
              ? "text-indigo-500 border-b-2 border-indigo-500"
              : "text-gray-500"
          }`}
          onClick={() => handleTabClick(1)}
        >
          Designs
        </button>
        <button
          className={`py-2 px-4 w-1/3 ${
            activeTab === 2
              ? "text-indigo-500 border-b-2 border-indigo-500"
              : "text-gray-500"
          }`}
          onClick={() => handleTabClick(2)}
        >
          Build
        </button>
        <button
          className={`py-2 px-4 w-1/3 ${
            activeTab === 3
              ? "text-indigo-500 border-b-2 border-indigo-500"
              : "text-gray-500"
          }`}
          onClick={() => handleTabClick(3)}
        >
          Materials
        </button>
      </div>

      {/* Tab Contents */}
      <div className="p-4">
        {activeTab === 1 && (
          <Designs designPageState={designPageState} dispatch={dispatch} />
        )}
        {activeTab === 2 && (
          <>
            {designs.length === 0 && (
              <Picker designPageState={designPageState} dispatch={dispatch} />
            )}
            <MaterialList
              designPageState={designPageState}
              dispatch={dispatch}
            />
          </>
        )}
        {activeTab === 3 ? (
          <AllMaterials
            designPageState={designPageState}
            dispatch={dispatch}
            designs={designs}
            mutate={mutate}
          />
        ) : null}
      </div>
    </div>
    // <Card>
    //   <div>
    //     <h1 className="text-5xl font-bold">Design</h1>

    //     <Tabs
    //       value={tab}
    //       onChange={handleTabChange}
    //       aria-label="basic tabs example"
    //     >
    //       <Tab label="Designs" />
    //       <Tab label="Build" />
    //       <Tab label="All Materials" />
    //     </Tabs>

    //     <TabPanel value={tab} index={0}>
    //       <Designs designPageState={designPageState} dispatch={dispatch} />
    //     </TabPanel>
    //     <TabPanel value={tab} index={1}>
    // {designs.length < 3 && (
    //   <Picker designPageState={designPageState} dispatch={dispatch} />
    // )}
    // <MaterialList designPageState={designPageState} dispatch={dispatch} />
    //     </TabPanel>
    //     <TabPanel value={tab} index={2}>
    // <AllMaterials
    //   designPageState={designPageState}
    //   dispatch={dispatch}
    //   designs={designs}
    //   mutate={mutate}
    // />
    //     </TabPanel>
    //   </div>
    // </Card>
  );
};

const AllMaterials = ({ designs }) => {
  const getUniqueMaterials = () => {
    const materials = designs.reduce((acc, design) => {
      design.materials.forEach((material) => {
        // Initialize `acc[material.material]` if it doesn't exist
        if (!acc[material.material]) {
          acc[material.material] = {};
        }

        // Initialize `acc[material.material][material.price]` if it doesn't exist
        if (!acc[material.material][material.price]) {
          acc[material.material][material.price] = [];
        }

        // Push `design.name` into the array
        acc[material.material][material.price].push(design.name);
      });
      return acc;
    }, {});
    return materials;
  };

  if (designs && designs.length) {
    const mats = getUniqueMaterials();
    // console.log(mats);

    return (
      <div>
        <h1 className="text-3xl font-semibold">Bulk Edit</h1>
        <div>
          {Object.keys(mats)
            .sort((a, b) => a.localeCompare(b))
            .map((material, idx) => {
              // if (Object.values(mats[material]).length === 1) {
              //   return null;
              // }

              return (
                <div
                  className="border-solid border-zinc-300 rounded-lg border-4 p-2"
                  key={`${v4()}`}
                >
                  <h3 className="text-2xl font-semibold">{material}</h3>
                  {Object.entries(mats[material]).map((k) => {
                    const designFound = k[1].map((id) => {
                      // console.log(id);
                      return designs.find((design) => design.name === id);
                    });

                    return (
                      <div key={`${v4()}`}>
                        <EditDesignBulk
                          designFound={designFound}
                          price={k[0]}
                          material={material}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    );
  } else {
    return <p></p>;
  }
};

const EditDesignBulk = ({ designFound, material, price }) => {
  const { mutate } = useSWRConfig();

  const { isLoading, error, designs, isValidating } = useDesigns();
  // const [ready, setReady] = useState(false);
  const [editNameReady, setEditNameReady] = useState(false);
  const [editNameConfirm, setEditNameConfirm] = useState(false);
  const [editPriceConfirm, setEditPriceConfirm] = useState(false);

  if (isValidating) {
    return (
      <div>
        <p>validating</p>
        <div className="spinner" />
      </div>
    );
  }

  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const editPrice = async (values) => {
    const promises = designFound.map(async (design) => {
      const newMaterials = design.materials.map((mat) => {
        if (mat.material === material) {
          return { ...mat, price: values.price };
        }
        return mat;
      });

      const newDesign = { materials: newMaterials };
      await api("PUT", `/team/design/${design.id}`, newDesign);
    });

    await Promise.all(promises);
    console.log(designs);
    await mutate("/team/designs", {
      revalidate: true,
      populateCache: true,
    });

    // setReady(false);
  };

  const editName = async (values) => {
    const promises = designFound.map(async (design) => {
      const newMaterials = design.materials.map((mat) => {
        if (mat.material === material) {
          return { ...mat, material: values.material };
        }
        return mat;
      });

      const newDesign = { materials: newMaterials };
      await api("PUT", `/team/design/${design.id}`, newDesign);
    });

    await Promise.all(promises);
    // console.log(designs);
    await mutate("/team/designs", {
      revalidate: true,
      populateCache: true,
    });

    // setEditNameReady(false);
  };

  return (
    <div className="border-solid border-zinc-300 rounded-lg border-4 p-2 ">
      <div className="grid lg:grid-cols-4 items-center">
        <div className="lg:col-span-4 mb-2">
          <ButtonTW
            variant="primary"
            label={"Change Material Version Name"}
            onClick={() => setEditNameReady(!editNameReady)}
          />
        </div>
        {editNameReady && (
          <div className="lg:col-span-4">
            <Formik
              initialValues={{
                material,
              }}
              validationSchema={Yup.object().shape({
                material: Yup.string().required("Required"),
              })}
              onSubmit={() => setEditNameConfirm(true)}
            >
              {({ submitForm, isSubmitting, values }) => (
                <div className="grid lg:grid-cols-4">
                  <div className="lg:col-span-3">
                    <Form>
                      <TextFieldTW name="material" label="Name" />
                    </Form>
                  </div>
                  <div className="flex lg:col-span-1 justify-center items-center mt-1">
                    <div>
                      <ButtonTW
                        variant="primary"
                        onClick={submitForm}
                        label={"Bulk Edit"}
                        className="col-span-2"
                      />
                    </div>
                  </div>
                  <ModalTW
                    confirm={() => editName(values)}
                    isOpen={editNameConfirm}
                    onClose={() => setEditNameConfirm(false)}
                    text={`Are you sure you want to edit the name of this material? From ${material} to ${values.material}`}
                  />
                </div>
              )}
            </Formik>
          </div>
        )}

        {/* <ButtonTW
        variant="primary"
        onClick={() => setReady(!ready)}
        label={`Change Price of $${price}`}
      /> */}

        <div className="lg:col-span-4">
          <Formik
            initialValues={{
              price,
            }}
            validationSchema={Yup.object().shape({
              price: Yup.number().required("Required"),
            })}
            onSubmit={() => setEditPriceConfirm(true)}
          >
            {({ submitForm, isSubmitting, values }) => (
              <Form>
                {" "}
                <div className="grid lg:grid-cols-4">
                  <TextFieldTW
                    name="price"
                    type="number"
                    label="Price ($)"
                    className="lg:col-span-3"
                  />
                  <div className="items-center flex justify-center">
                    <ButtonTW
                      variant="primary"
                      // disabled={isSubmitting}
                      onClick={submitForm}
                      label={"Bulk Edit"}
                    />
                  </div>

                  <ModalTW
                    confirm={() => editPrice(values)}
                    isOpen={editPriceConfirm}
                    onClose={() => setEditPriceConfirm(false)}
                    text={`Are you sure you want to edit the price of this material? From ${price} to ${values.price}`}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <p>{designFound.map((d) => d.name).join(", ")}</p>
    </div>
  );
};

const DesignRow = ({ design, dispatch, designPageState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, designs } = useDesigns();
  const [areYouSureImage, setAreYouSureImage] = useState(false);
  const [areYouSureDesign, setAreYouSureDesign] = useState(false);

  // if (error) {
  //   return <p>Error! please refresh the page, if the problem persists contact fencewizapp@gmail.com</p>;
  // }
  const saveButton = async (values, actions) => {
    enqueueSnackbar("Saving Design Name");
    await api("PUT", `/team/design/${design.id}`, values);
    mutate(designs.map((d) => (d.id === design.id ? { ...d, ...values } : d)));
    enqueueSnackbar("Design Name Updated", { variant: "success" });
  };

  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = async () => {
        // setOriginalImage(event.target.result);
        const aspectRatio = img.width / img.height;
        const maxWidth = 2000;
        const maxHeight = 2000;

        let targetWidth;
        let targetHeight;

        if (img.width > maxWidth || img.height > maxHeight) {
          if (img.width > img.height) {
            targetWidth = maxWidth;
            targetHeight = maxWidth / aspectRatio;
          } else {
            targetHeight = maxHeight;
            targetWidth = maxHeight * aspectRatio;
          }
        } else {
          targetWidth = img.width;
          targetHeight = img.height;
        }

        createImageBitmap(img, {
          resizeWidth: targetWidth,
          resizeHeight: targetHeight,
        }).then(async (resizedBitmap) => {
          let quality = 0.99;
          let canvas = document.createElement("canvas");
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(resizedBitmap, 0, 0, targetWidth, targetHeight);
          let dataUrl = canvas.toDataURL("image/jpeg", quality);

          while (dataUrl.length > 1000000) {
            quality -= 0.01;
            dataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          // console.log(dataUrl);
          enqueueSnackbar("Saving Design Image");

          api("PUT", `/team/design/${design.id}`, { image: dataUrl });
          mutate(
            designs.map((d) =>
              d.id === design.id ? { ...d, image: dataUrl } : d
            ),
            { revalidate: false }
          );
          enqueueSnackbar("Design Image Updated", { variant: "success" });
          // setResizedImage(dataUrl);
        });
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  const areYouSureButtonDeleteDesign = async () => {
    try {
      enqueueSnackbar("Deleting Design");

      await api("DELETE", `/team/design/${design.id}`);
      mutate(designs.filter((d) => d.id !== design.id));
      enqueueSnackbar("Deleted", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(e.response.data, { variant: "error" });
    }
    setAreYouSureImage(false);
  };

  const areYouSureButtonDeleteImage = async (values, actions) => {
    try {
      enqueueSnackbar("Deleting Image");
      const newDesigns = designs.map((d) =>
        d.id === design.id ? { ...d, image: null } : d
      );

      await api("PUT", `/team/design/${design.id}`, { image: null });
      mutate(newDesigns);
      enqueueSnackbar("Deleted", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(e.response.data, { variant: "error" });
    }
    setAreYouSureImage(false);
  };

  const deleteImageButton = () => setAreYouSureImage(true);

  const deleteDesignButton = () => setAreYouSureDesign(true);

  const copyToTable = () => {
    dispatch({ type: "resetDesign" });

    dispatch({ type: "copyDesign", payload: design });
    enqueueSnackbar("Copied", { variant: "success" });

    // setMaterials(design.materials);
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: design.name,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        })}
        onSubmit={saveButton}
      >
        {({ submitForm, isSubmitting, setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-5 gap-x-1 content-center my-4 border-solid border-zinc-300 rounded-lg border-4 p-2 items-center">
              <div className="col-span-5 text-center">
                <TextFieldTW name="name" label="Design Name:" />
              </div>
              {design.image ? (
                <div className="col-span-5 flex justify-center">
                  <img
                    alt="design"
                    src={design.image}
                    className="my-1 min-h-48 max-h-48"
                    // style={{
                    //   width: "400px",
                    //   maxWidth: "100%",
                    //   textAlign: "center",
                    // }}
                  />
                </div>
              ) : (
                <div className="my-1 min-h-48 col-span-5 flex justify-center">
                  <p> No Image</p>
                </div>
              )}
              {isSubmitting ? (
                <div className="spinner" />
              ) : (
                <ButtonTW
                  variant="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  label={<SaveIcon />}
                />
              )}
              <ButtonTW
                variant="primary"
                onClick={copyToTable}
                label={<ContentCopyIcon />}
              />
              <div className="transform hover:scale-105 hover:shadow-2xl px-3 py-2 inline-flex items-center justify-center font-semibold transition ease-in-out duration-200 rounded focus:outline-none focus:ring-4 text-white bg-gradient-to-r from-indigo-500 via-blue-500 to-indigo-500 hover:from-indigo-500 hover:via-purple-500 hover:to-indigo-500 shadow-lg shadow-indigo-500/50 focus:ring-indigo-400">
                <label htmlFor="designUpload">
                  <ImageIcon />
                </label>

                <input
                  type="file"
                  accept="image/*"
                  id="designUpload"
                  onChange={handleImageChange}
                  className="hidden"
                />
              </div>

              {design.image ? (
                <ButtonTW
                  variant="danger"
                  label={<ImageIcon />}
                  onClick={deleteImageButton}
                />
              ) : null}

              <ModalTW
                isOpen={areYouSureImage}
                text="Are you sure you want to delete this image?"
                confirm={areYouSureButtonDeleteImage}
                onClose={() => setAreYouSureImage(false)}
              />

              <ButtonTW
                variant="danger"
                label={<DeleteIcon />}
                onClick={deleteDesignButton}
              />

              <ModalTW
                isOpen={areYouSureDesign}
                text="Are you sure you want to delete this design?"
                confirm={areYouSureButtonDeleteDesign}
                onClose={() => setAreYouSureDesign(false)}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const Designs = ({ dispatch, designPageState }) => {
  const { designs, isLoading, error } = useDesigns();
  const { team, isLoading: teamIsLoading, error: teamError } = useTeam();

  if (isLoading || teamIsLoading || !designs) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  return (
    <div>
      <h2 className="text-xl mb-5">{team.team.name} Designs</h2>
      {team.tierLimits.designs <= designs.length && (
        <p>No More Designs Allowed</p>
      )}
      {designs.length === 0 ? <p>No Designs Yet</p> : null}

      <div className="md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-x-4">
        {designs && designs.length
          ? designs
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((design) => (
                <DesignRow
                  key={design.id}
                  design={design}
                  designPageState={designPageState}
                  dispatch={dispatch}
                />
              ))
          : null}
      </div>
    </div>
  );
};

export default Design;
