import { Formik, Form } from "formik";

import * as Yup from "yup";
import { navigate } from "@reach/router";
import { useTeam } from "../../hooks/useTeam";
import { api } from "../../util/api";
import { useSnackbar } from "notistack";
import TextFieldTW from "../../comp/tw/TextField";
import camelToTitle from "../../util/case";
import { ButtonTW } from "../../comp/tw/Button";

const AddUser = () => {
  const { mutate, team, isLoading, error } = useTeam();
  const { enqueueSnackbar } = useSnackbar();

  const addUser = async (values, actions) => {
    try {
      // const newTeam =
      enqueueSnackbar("User Creating..");

      await api("POST", "/team/user", values);
      enqueueSnackbar("User Added, Have them create an account", {
        variant: "success",
      });
      navigate("/team");
      mutate();
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: "error" });

      // actions.setFieldError("email", error.response.data);
      // console.log();
      // console.log(Object.keys(error));
    }

    // console.log(newTeam);

    // if (newTeam.status === 200) {

    // } else {
    //
    // }
  };

  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  return (
    <div>
      <div>
        <h1 className="text-4xl font-bold">Add User</h1>
        <br />
        <Formik
          initialValues={{
            email: "",
            firstName: "",
            lastName: "",
            role: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email is invalid")
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
            firstName: Yup.string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
            lastName: Yup.string()
              .min(1, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
            role: Yup.string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
          })}
          onSubmit={addUser}
        >
          {({ submitForm, isSubmitting, values, handleChange }) => (
            <Form>
              <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-4">
                <TextFieldTW name="email" label="Email" />
                <TextFieldTW name="firstName" label="First Name" />
                <TextFieldTW name="lastName" label="Last Name" />
                <div>
                  <label
                    htmlFor="filterType"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Role:
                  </label>
                  <select
                    id="role"
                    value={values.role}
                    onChange={handleChange}
                    className="mt-1 p-3 w-full rounded-md border border-gray-300 "
                  >
                    {team.tierLimits.roles.map((role) => (
                      <option key={role} value={role}>
                        {camelToTitle(role)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {isSubmitting ? (
                <div className="spinner" />
              ) : (
                <ButtonTW
                  variant="primary"
                  className="my-4"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  label={"Invite User"}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default AddUser;
