import { useRef } from "react";
import { useParams } from "@reach/router";
import { useProject } from "../../hooks/useProject";
import { useTeam } from "../../hooks/useTeam";
import camelToTitle from "../../util/case";
import { api } from "../../util/api";
import { enqueueSnackbar } from "notistack";
import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useState } from "react";
import { ButtonTW } from "../../comp/tw/Button";

export const statuses = [
  "notQuoted",
  "quoted",
  "noAnswer",
  "onHold",
  "ready",
  "done",
];

export const Notes = () => {
  const { projectId } = useParams();
  const { project, isLoading, error, mutate } = useProject(projectId);
  const { team, isLoading: teamIsLoading, error: teamError } = useTeam();
  const timeoutRef = useRef(null);

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  // const saveClientButton = async (values) => {
  //   enqueueSnackbar("Saving Client Details");
  //   await api("PUT", `/project/${projectId}`, values);
  //   await mutate();
  //   enqueueSnackbar("Notes Updated", { variant: "success" });
  // };

  const handleChangeStatus = async (e) => {
    enqueueSnackbar("Saving Status");
    await api("PUT", `/project/${projectId}`, { status: e.target.value });
    await mutate();
    enqueueSnackbar("Status Updated", { variant: "success" });
  };

  const { status } = project;

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      enqueueSnackbar("Optimizing Picture");

      const reader = new FileReader();

      reader.onload = async (event) => {
        const img = new Image();
        img.onload = () => {
          // setOriginalImage(event.target.result);
          const aspectRatio = img.width / img.height;
          const maxWidth = 2000;
          const maxHeight = 2000;

          let targetWidth;
          let targetHeight;

          if (img.width > maxWidth || img.height > maxHeight) {
            if (img.width > img.height) {
              targetWidth = maxWidth;
              targetHeight = maxWidth / aspectRatio;
            } else {
              targetHeight = maxHeight;
              targetWidth = maxHeight * aspectRatio;
            }
          } else {
            targetWidth = img.width;
            targetHeight = img.height;
          }

          // setWidth(targetWidth);
          // setHeight(targetHeight);

          createImageBitmap(img, {
            resizeWidth: targetWidth,
            resizeHeight: targetHeight,
          })
            .then(async (resizedBitmap) => {
              let quality = 0.99;
              let canvas = document.createElement("canvas");
              canvas.width = targetWidth;
              canvas.height = targetHeight;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(resizedBitmap, 0, 0, targetWidth, targetHeight);
              let dataUrl = canvas.toDataURL("image/jpeg", quality);
              // console.log(dataUrl.length);
              while (dataUrl.length > 1000000) {
                console.log(dataUrl.length);
                quality -= 0.01;
                dataUrl = canvas.toDataURL("image/jpeg", quality);
              }

              enqueueSnackbar("Uploading Picture...");

              const newImage = await api(
                "POST",
                `/project/${projectId}/image`,
                {
                  image: dataUrl,
                }
              );
              enqueueSnackbar("Upload Complete", { variant: "success" });

              mutate(newImage, { revalidate: false });
            })
            .catch((e) => {
              console.log(e);
            });
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="py-4 ">
      <div className="lg:grid lg:grid-cols-4 gap-4">
        <div className="lg:col-span-3 ">
          <label className="block text-sm font-medium text-gray-700">
            Notes:
          </label>
          <textarea
            onChange={(e) => {
              if (team.permissions.includes("editProject")) {
                mutate(
                  { ...project, notes: e.target.value },
                  {
                    optimisticData: { ...project, notes: e.target.value },
                    revalidate: false,
                  }
                );
                clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(async () => {
                  enqueueSnackbar("Saving Notes");

                  await api("PUT", `/project/${projectId}`, {
                    notes: e.target.value,
                  });
                  enqueueSnackbar("Saving Notes", { variant: "success" });

                  // mutate();
                  // Perform other actions here, like making API calls or updating state
                }, 1000);
              }
            }}
            value={project.notes}
            className={`mt-1   p-3 w-full h-24 rounded-md border focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all ease-in-out duration-200`}
          />
        </div>
        <div>
          <label
            htmlFor="filterType"
            className="block text-sm font-medium text-gray-700"
          >
            Status:
          </label>
          <select
            id="filterType"
            value={status}
            onChange={handleChangeStatus}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md"
          >
            {statuses.map((status) => (
              <option key={status} value={status}>
                {camelToTitle(status)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 py-4">
        {project.images &&
          Object.entries(project.images).map((i, idx) => {
            return (
              <ImageButtons
                key={idx}
                image={i[1]}
                imageId={i[0]}
                project={project}
              />
            );
          })}
      </div>

      <div>
        <label
          htmlFor="raised-button-file"
          className="transform hover:scale-105 hover:shadow-2xl px-3 py-2 inline-flex items-center justify-center font-semibold transition ease-in-out duration-200 rounded focus:outline-none focus:ring-4 text-white bg-gradient-to-r from-indigo-500 via-blue-500 to-indigo-500 hover:from-indigo-500 hover:via-purple-500 hover:to-indigo-500 shadow-lg shadow-indigo-500/50 focus:ring-indigo-400"
        >
          <AddPhotoAlternateIcon />
          {/* {fileName ? `Selected: ${fileName}` : "Upload Image"} */}
        </label>

        <input
          type="file"
          accept="image/*"
          id="raised-button-file"
          onChange={handleImageChange}
          className="hidden"
        />
      </div>
    </div>
    // <Card>
    //   <div>
    //     <h1>Notes</h1>

    // <Formik
    //   initialValues={{
    //     notes,
    //     status,
    //   }}
    //   onSubmit={saveClientButton}
    // >
    //   {({ submitForm, isSubmitting }) => (
    //     <Form>
    //       <Grid container>
    //         <Grid item xs={12} md={12} lg={12}>
    //           <div
    //             style={{
    //               display: "flex",
    //               justifyContent: "center",
    //               // width: "100%"
    //             }}
    //           >
    //             <Field
    //               component={TextField}
    //               name={"notes"}
    //               label={"Notes"}
    //               multiline
    //               style={{
    //                 width: "100%",
    //               }}
    //             />
    //           </div>
    //         </Grid>
    //         <Grid item xs={12} md={12} lg={12}>
    //           <div>
    //             {isSubmitting ? (
    //               <div className="spinner" />
    //             ) : team.permissions.includes("editProject") ? (
    //               <Button
    //                 variant="contained"
    //                 color="primary"
    //                 disabled={isSubmitting}
    //                 onClick={submitForm}
    //               >
    //                 <Save />
    //               </Button>
    //             ) : null}
    //           </div>
    //         </Grid>
    //       </Grid>
    //     </Form>
    //   )}
    // </Formik>

    //     <FormControl fullWidth>
    //       <InputLabel
    //         id="status"
    //         disabled={!team.permissions.includes("editProject")}
    //       >
    //         Status
    //       </InputLabel>
    //       <Select
    //         labelId="status"
    //         id="statusId"
    //         value={status}
    //         label="Status"
    //         onChange={handleChangeStatus}
    //       >
    //         {statuses.map((s) => {
    //           return (
    //             <MenuItem key={s} value={s}>
    //               {camelToTitle(s)}
    //             </MenuItem>
    //           );
    //         })}
    //       </Select>
    //     </FormControl>
    //   </div>
    //   <Grid container>
    // {project.images &&
    //   Object.entries(project.images).map((i) => {
    //     return <ImageButtons image={i[1]} imageId={i[0]} />;
    //   })}
    //   </Grid>

    // <div>
    //   <input
    //     type="file"
    //     accept="image/*"
    //     style={{ display: "none" }}
    //     id="raised-button-file"
    //     onChange={handleImageChange}
    //   />
    //   <label htmlFor="raised-button-file">
    //     <Button component="span" variant="contained">
    //       <AddPhotoAlternateIcon />
    //     </Button>
    //   </label>
    // </div>
    // </Card>
  );
};

const ImageButtons = ({ image, imageId, project }) => {
  const { projectId } = useParams();

  const { mutate } = useProject(projectId);
  const { team } = useTeam();
  const [areYouSure, setAreYouSure] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const deletePictureButton = async () => setAreYouSure(true);

  const areYouSureButton = async () => {
    enqueueSnackbar("Image Deleting...");
    console.log(project);
    console.log(imageId);

    delete project.images[imageId];
    // const { , ...images } = project.images;
    // console.log(imageId);

    await mutate(api("DELETE", `/project/${projectId}/image/${imageId}`), {
      optimisticData: project,
      revalidate: false,
    });
    enqueueSnackbar("Image Deleted", { variant: "success" });
    // navigate("/team");
  };

  return (
    <div
      key={imageId}
      className={`max-w overflow-hidden relative transition-shadow duration-200 ease-in-out cursor-pointer `}
    >
      <img
        className={`w-full ${!isExpanded && "h-64"} object-cover`}
        src={image}
        onClick={toggleExpand}
        alt={imageId}
      />

      {isExpanded &&
      team.permissions.includes("editProject") &&
      areYouSure === false ? (
        <ButtonTW
          variant="danger"
          label={<DeleteIcon />}
          onClick={deletePictureButton}
        />
      ) : null}
      {areYouSure ? (
        <>
          <ButtonTW
            variant="danger"
            label={"Are you sure?"}
            onClick={areYouSureButton}
          />
          <ButtonTW
            variant="primary"
            label={"No"}
            onClick={() => setAreYouSure(false)}
          />
        </>
      ) : null}

      {/* Delete Button */}
    </div>
    // <Grid item xs={12} md={12} lg={12}>
    //   <div>
    //     <img src={image} style={{ width: "100%" }} alt={"imageOfProject"} />

    //     {/* <DeleteIcon /> */}
    // {team.permissions.includes("editProject") && areYouSure === false ? (
    //   <Button
    //     variant="contained"
    //     color="error"
    //     onClick={deletePictureButton}
    //   >
    //     <DeleteIcon />
    //   </Button>
    // ) : null}

    // {areYouSure ? (
    //   <>
    //     <Button
    //       variant="contained"
    //       color="error"
    //       onClick={areYouSureButton}
    //     >
    //       Are you sure?
    //     </Button>
    //     <Button variant="contained" onClick={() => setAreYouSure(false)}>
    //       No
    //     </Button>
    //   </>
    // ) : null}
    //   </div>
    // </Grid>
  );
};
