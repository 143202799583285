import { useEffect } from "react";
import { navigate, useParams } from "@reach/router";
import { useProject } from "../../hooks/useProject";
import { useTeam } from "../../hooks/useTeam";

import { api } from "../../util/api";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { Materials } from "./ProjectMaterials";
import { ClientFormAddress } from "./ClientFormAddress";
import { Lengths } from "./Lengths";
import { DesignPicker } from "./DesignPicker";
import { ProjectNumbers } from "./ProjectNumbers";
import { Notes } from "./Notes";
import { ButtonTW } from "../../comp/tw/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModalTW } from "../../comp/tw/Modal";

const Project = () => {
  const { projectId } = useParams();
  const { project, isLoading, error, mutate } = useProject(projectId);
  const [areYouSure, setAreYouSure] = useState(false);

  const { team, isLoading: teamIsLoading, error: teamError } = useTeam();

  useEffect(() => {
    if (
      project?.polylines &&
      project.polylines[0] &&
      project.polylines[0]["lng"]
    ) {
      console.log("FIX PROJECT");
      const newProjectPoly = [[...project.polylines]];
      mutate(
        api("PUT", `/project/${project.id}`, {
          polylines: newProjectPoly,
        }),
        {
          optimisticData: {
            ...project,
            polylines: newProjectPoly,
          },
        }
      );
    }
  }, [mutate, project]);

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const deleteButton = async () => {
    enqueueSnackbar("Deleting Project...");
    await api("DELETE", `/project/${project.id}`);
    enqueueSnackbar("Deleted", { variant: "success" });
    navigate(`/`);
  };

  return (
    <>
      <div>
        <h1 className="text-4xl font-bold mb-4">Project</h1>
        <ClientFormAddress />
        <Notes />

        <Lengths project={project} mutate={mutate} />
        <DesignPicker />

        {project.designId ? (
          <>
            <Materials />
            <ProjectNumbers />
          </>
        ) : null}

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        {team.permissions.includes("deleteProject") ? (
          <ButtonTW
            variant="danger"
            color="error"
            onClick={() => setAreYouSure(true)}
            label={<DeleteIcon />}
          >
            Delete
          </ButtonTW>
        ) : null}

        <ModalTW
          isOpen={areYouSure}
          onClose={() => setAreYouSure(false)}
          text="Are you sure you want to delete the project?"
          confirm={deleteButton}
        />
      </div>
    </>
  );
};

export default Project;
